import {gotoUrl} from '../../../../web/utils/util';
export default {
  navigatorTo: (params) => {
    gotoUrl(params.url, { params });
  },
  navigateBack: (params) => {
    window.history.go(params.delta * -1);
  },
  redirectTo: (params) => {
    gotoUrl(params.url, { params, replace: true });
  },
}
