//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from 'vuex';
import Chatbot from './index.vue';
import { getChatbotConfig, CHAT_BOT_DEFAULT_ICON } from './utils/chatbot';

export default {
  components: { Chatbot },
  props: {
    // 来源页面
    pageFrom: {
      type: String,
      default: '',
    },
    // AI前置请求参数 比如 { request: '正文内容' }
    beforeRequestPramasFunc: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isInit: false,
      chatbotVisible: false,
      leftBoxWidth: 0,
      rightBoxWidth: 0,
      config: null,
    };
  },
  computed: {
    chatbotName() {
      return this.config?.name ?? '智能助手';
    },
    chatbotIcon() {
      return this.config?.img ?? CHAT_BOT_DEFAULT_ICON;
    },
    visible() {
      return this.isInit && !this.chatbotVisible;
    },
    hasPermission() {
      return !!this.config?.entry_pages?.includes(this.pageFrom);
    },
    chatbotStyle() {
      const entryPosition = this.config?.entry_position || {};
      const style = {};

      if (this.rightBoxWidth || entryPosition.right) {
        // entry_init_right:0, 进去全显示,然后变短,
        // entry_init_right:'-200px', 从右侧滑出
        // entry_init_right:'-100vw', 几乎无动画
        style.right = this.rightBoxWidth ? `-${this.rightBoxWidth}px` : entryPosition.right || 0;
      }
      if (entryPosition.bottom) {
        style.bottom = entryPosition.bottom; // 默认208px
      }
      return style;
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    open() {
      this.$refs.chatbot.open();
    },
    async init() {
      if (this.isInit) {
        return;
      }
      this.config = await getChatbotConfig();
      this.calcAnimatedWidth();
      this.isInit = true;
    },
    calcAnimatedWidth(retryCount = -1) {
      if (this.rightBoxWidth) {
        return;
      }
      setTimeout(async () => {
        // this.leftBoxWidth = this.$refs['left-box']?.offsetWidth || 52;
        // 重试多次失败后
        this.rightBoxWidth = this.$refs['right-box']?.offsetWidth || (retryCount === 0 ? 0.01 : 0);
        if (retryCount > 0) {
          this.calcAnimatedWidth(retryCount - 1);
        }
      }, 150);
    },
    handleClick() {
      this.open();
    },
    handleChatbotVisibleChanged(val) {
      this.chatbotVisible = val;
    },
  },
};
