import { request } from '../../request';
import { getAppid } from '../../common';
import { lstore } from '../../util';
import { isEmpty, size } from 'lodash';

// options英文语言包 (建议内置语言包放在当前目录下面, 但是目前修改阶段, 多人提交量过大, 不太方便移动)
import optionsEn from '../opstions_en';
import LANG_EN_DEFAULT from '../en';
import LANG_ZH_DEFAULT from '../zh';
const _ = { isEmpty, size };

const globalLanguageJson = {}; // { appid: { en : {}, zh: {} }}

/**
 * 获取工程里面的语言包
 * @param {*} langType 语言
 * @returns 返回语言包, 不存在返回null
 */
function getProjectLanguange(langType) {
  // 无数据 已本地优先,
  if (langType === 'zh') {
    return { ...LANG_ZH_DEFAULT };
  }
  if (langType === 'en') {
    return { ...optionsEn, ...LANG_EN_DEFAULT };
  }
  return null;
}

/**
 * 读取缓存语言包
 * @param {*} langType 语言 zh / en
 * @returns 返回语言包, 不存在返回null
 */
export function getLanguageFromCache(langType) {
  if (langType) {
    const appid = getAppid();
    const langObj = lstore.getItem(`${appid}_lang_${langType}`);
    const projectLangObj = getProjectLanguange(langType);

    if (_.isEmpty(langObj)) {
      return projectLangObj;
    }
    // @ts-ignore
    if (projectLangObj && (projectLangObj.$version || 0) > (langObj.$version || 0)) {
      // 本地版本 大于  缓存版本, 本地覆盖缓存
      return { ...langObj, ...projectLangObj };
    }
    return langObj;
  }
  return null;
}

/**
 * 在线获取语言包
 * @param {*} langType 语言类型 zh / en, 不传表示, 后台根据默认值纠正
 * @returns 语言对象{ langType: '', langObj: '', error: '' }
 * langType语言  langObj语言包 error错误提示
 * TODO: WEB端未在本次需求, 且后台未考虑, 暂不实现;
 */
export async function getLanguage(langType = '') {
  const retData = { langType: '', langObj: '', error: '' };
  const appid = getAppid();

  const res = await request('NgesServer/NgesApp/GetTenantLanguage', {
    appid,
    lang_list: langType,
  }).catch(() => undefined);
  console.log('GetLanguage', res);

  langType = res?.lang_type;
  if (langType) {
    let languageJson = globalLanguageJson[appid];
    if (!languageJson) {
      languageJson = {};
      globalLanguageJson[appid] = languageJson;
    }

    // eslint-disable-next-line camelcase
    const value = JSON.parse(res?.merger_json || '{}');
    if (value.$version === undefined) {
      value.$version = 0; // 不存在 默认写个版本号
    }
    if (langType === 'zh' || _.size(value) > 20) {
      // 语言包数量太少, 其实可以判断为语言包缺失(中文除外)
      retData.langType = langType;
      retData.langObj = value;
      languageJson[langType] = value;
      lstore.setItem(`${appid}_lang_${langType}`, value, 24 * 3600 * 30);
    }
  }

  if (!retData.langType) {
    retData.error = '当前语言包缺失';
  }

  return retData;
}

// export function getGlobalLanguageJson() {
//   return globalLanguageJson[getAppid()];
// }
