//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { expiredDefaultImgNew } from 'nges-common/src/util';
import CommonDialog from '@/components/commonDialog';
// 目前用于 文章详情  内容列表配置 内容列表详情配置
export default {
  name: 'ImageCover',
  components: {
    CommonDialog,
  },
  props: {
    title: {
      type: [String, null, undefined],
      default: '选择封面',
    },
    layoutJson: {
      type: [Object, null, undefined],
      default: null,
    },
    dialogType: {
      type: String,
      default: '',
    },
    editId: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    isEffective: {
      type: Boolean,
      default: true,
    },
    tips: {
      type: String,
      default: '',
    },
    tipsPosition: {
      type: String,
      default: 'bottom',
    },
    width: {
      type: String,
      default: '80px',
    },
    height: {
      type: String,
      default: '80px',
    },
    showStyle: {
      type: String,
      default: 'blue', // 默认值 蓝色blue, gray
    },
    showOperateButton: {
      type: Boolean,
      default: false,
    },
    // 建议封面大小 (0 为不限制)
    imageWidth: {
      type: [Number, String, null, undefined],
      default: 0,
    },
    // 建议封面大小(0 为不限制)
    imageHeight: {
      type: [Number, String, null, undefined],
      default: 0,
    },
  },
  data() {
    return {
      imageData: { url: '', width: 0, height: 0 },
      expiredDefaultImgNew,
      commonDialogType: '',
    };
  },
  computed: {
    imageTipsPosition() {
      return this.tipsPosition || 'bottom';
    },
    imageTips() {
      const tips = [];
      if (this.tips) {
        tips.push(this.tips);
      }
      const imageWidth = this.imageWidth ? Number(this.imageWidth) : 0;
      const imageHeight = this.imageHeight ? Number(this.imageHeight) : 0;
      if (imageWidth && imageHeight) {
        if (this.imageData.width && this.imageData.height) {
          if (this.imageData.width !== imageWidth || this.imageData.height !== imageHeight) {
            tips.push(`封面尺寸：${this.imageData.width} × ${this.imageData.height} px`);
            tips.push(`视频尺寸：${imageWidth} × ${imageHeight} px`);
          }
        } else {
          tips.push(`视频尺寸：${imageWidth} × ${imageHeight} px`);
        }
      }

      return tips;
    },
    tipsClass() {
      return ['tips', `tips-position-${this.imageTipsPosition}`].join(' ');
    },
  },
  watch: {
    url: {
      handler(val) {
        this.imageData.url = val;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},

  methods: {
    handleImageLoad(event) {
      const { naturalWidth, naturalHeight } = event?.target || {};
      if (naturalWidth && naturalHeight) {
        this.imageData.width = naturalWidth;
        this.imageData.height = naturalHeight;
      }
    },
    selectImg() {
      this.commonDialogType = this.dialogType;
      // this.selectImgVisible = true;
    },
    // 选择封面关闭
    // closeSelectimg() {
    //   this.selectImgVisible = false;
    // },
    // 必须勾选一张图片
    handleConfirmCommonDialog(params) {
      this.imageData.width = 0;
      this.imageData.height = 0;
      this.$emit('selectImage', params);
    },
    handleDelete() {
      this.imageData.url = '';
      this.imageData.width = 0;
      this.imageData.height = 0;
      const data = this.$refs.commonDialog.getEmitParams(null);
      if (!data.type) {
        const [type, detailType] = this.dialogType.split('@');
        data.type = type;
        data.detailType = detailType;
      }
      this.$emit('selectImage', data);
    },
  },
};
