import request from '@/libs/request';
import { insertDocLog } from '@/graphql/api/article';

export const getArticleCntInfo = function (data) {
  return request('NgesServer/StatisticService/QueryArticleBehaviorCnt', data);
};

export const getCourseListBehaviorCnt = function (data) {
  return request('NgesServer/StatisticService/GetCourseListBehaviorCnt', data);
};

// 生成短链
export const CreateShortUrl = function (data) {
  return request('NgesServer/ShortUrl/CreateShortUrl', data);
};

// 医生定级计算分数=az
export const VoteSaveGrade2 = function (data) {
  return request('FaasGatewayServer/func/SaveGrade', data);
};

// 上传adobe素材
export const AEMSelectorMaterialSync = function (data) {
  return request('FaasGatewayServer/func/AEMSelectorMaterialSync', data);
};

// 医生定级计算分数-标品
export const VoteSaveGradeStandard = function (data) {
  return request('CrmServer/VoteService/SaveGrade', data);
};

// 获取文档下载链接
export const AppletsCopyDocUrl = function (data) {
  return request('ApiServer/Cos/GetPrivateObjUrl', data);
};

// 文档日志
export const InsertDocumentLog = (data) => insertDocLog(data);
