import Vue from 'vue';
import VueRouter from 'vue-router';
import spaRoutes from './routes';
import main from '@/main';
import customRoutes from '@/custom/router.js';
import { GetAppConfig, GetUserMenuTree } from 'nges-common/src/web/utils/api';
import { getPageConfig, preloadWujieCustom } from 'nges-common/src/web/utils/common';
import login from '@/utils/login';
import { reportStart } from 'nges-common/src/web/utils/aegis';
Vue.use(VueRouter);

// 解决系统在维护状态时，多个请求同时拿到系统在维护中的状态，从而多次跳转到登录页导致的路由报错问题
// 例子：https://blog.51cto.com/u_15302032/4803600
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    console.log(err);
    return err;
  });
};
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => {
    console.log(err);
    return err;
  });
};

export const routes = [
  {
    path: '/',
    redirect: '/saas/homePage.html',
  },
  ...spaRoutes,
  ...customRoutes,
  {
    path: '*',
    redirect: '/saas/homePage.html',
  },
];
// base:process.env.BASE_URL,
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});
// router.beforeEach((to, from, next) => {
//   // 离开页面时记录定位
//   // from.meta.savedPosition = {
//   //   x: 0,
//   //   y: document.documentElement.scrollTop || document.body.scrollTop,
//   // };
//   // next();
// });

let wujieHasPreload = false;

// 路由后置钩子
router.afterEach(() => {
  main.$store.commit('user/setBreadcrumb', []); // 路由切换，页面面包屑清除
  reportStart('pagestart');
  reportStart('datastart');
});

router.beforeEach(async (to, from, next) => {
  const pathName = location.pathname;
  const LOGIN_PATH = '/saas/login.html';
  if (to.query?.token) {
    try {
      await login.ssoLogin(to.query);
      delete to.query.token;
      next({
        path: LOGIN_PATH,
        query: { ...to.query, oAuthIsLogin: 1 },
      });
      return;
    } catch (err) {
      console.error(`sso登录失败:${err.message}`);
      next({
        path: LOGIN_PATH,
        query: { ssoLoginFail: err.message },
      });
      return;
    }
  }
  if (to.query?.errcode && to.query?.errmsg && pathName !== LOGIN_PATH) {
    next({
      path: LOGIN_PATH,
      query: { errcode: to.query.errcode, errmsg: to.query.errmsg },
    });
    return;
  }
  if (to.query?.auth_error) {
    console.error(`sso登录失败:${to.query.auth_error}`);
    next({
      path: LOGIN_PATH,
      query: { ssoAuthFail: to.query.auth_error },
    });
    return;
  }
  try {
    const appConfig = await GetAppConfig();
    // 正常登录预加载无界组件，fix main.js App.vue 登录页循环预加载
    if (!wujieHasPreload && pathName !== LOGIN_PATH) {
      // 非登录页，准备跳首页前进行 preload
      try {
        const cpc = await getPageConfig('custom_package_config', 'object');
        const packageConfig = cpc?.web || {};
        preloadWujieCustom(packageConfig.tenant);
        wujieHasPreload = true;
      } catch (e) {
        console.error('preload wujie error', e);
      }
    }

    const webHomePath =
      appConfig?.page_config?.web_home_path || JSON.parse(appConfig?.ext_config)['web-home-path'];
    if (
      // 输入默认根目录匹配判断跳入首页
      (pathName === '/' && webHomePath && to.fullPath !== webHomePath) ||
      // 没权限时被 /saas/articleManagement.html 页面拦截，而此页面又是有业务逻辑的页面。
      // 通过判断自定义路由参数确定是否要跳到首页
      // 跳到第一个有权限的页面，如果有配置首页，第一个有权限页面一定是首页
      (to.params.toFirstRightRouter && webHomePath)
    ) {
      next({
        path: webHomePath,
      });
      return;
    }
  } catch (e) {}
  try {
    await Promise.all([GetUserMenuTree(), login.fetchUserInfo()]);
  } catch (e) {}
  next();
});

console.log('routes', routes);
export default router;
