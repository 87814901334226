/* eslint-disable no-useless-escape */
// 文章列表相关
// 获取文章列表
const GetArticleList = `query {data:mcm_article_list(_order_by: {update_time: _desc},$filter,$pageOption){id,name,send_range}}`;
// 创建文章列表
const CreateArticleList = `mutation insert{mcm_article_list(_values:{name:$title, send_range:$send_range,status:$status, permissions:$permissions,  notification:$noticeList, tollbar:$toolbarList, category:$category, official_id:$range_id, operation:$operation, project_info:$project_info, identity_tag: $identity_tag})}`;
// 更新文章列表
const UpdateArticleList = `mutation update {mcm_article_list(_where: {id: $id}, _set: {name:$title, send_range:$send_range,status:$status, permissions:$permissions,  notification:$noticeList, tollbar:$toolbarList, category:$category, official_id:$range_id,operation:$operation, project_info:$project_info, identity_tag: $identity_tag})}`;
// 获取文章列表详情
const ArticleListDetail = `query{mcm_article_list(id: $id){name, send_range, status, permissions, is_favorite, is_like, operation, notification, tollbar, official_id, category, project_info, identity_tag, menu_id, detail_data}}`;
// 获取文章列表内分类的文章数据
const ArticleListArticle = `query { mcm_article(_order_by: {update_time: _desc}, $filter, effective_status: \"1\") { id, title, product{id, name, status}, tag{id, name, status} } }`;
// 获取是否推荐
const kvConfigQuery = `
query {
  kv (key: {_in:["mcm_article_rank_switch", "mcm_article_apply_all"]}) {
    value
    key
  }
}`;
// 增删改查自定义内容页有效期
const GetArticleListValidScope = `query{ mcm_article_list_valid_scope (article_list_id:$id) {      start_time,      end_time,     } } `;

const InsertArticleListValidScope = `mutation insert{ mcm_article_list_valid_scope (_values: {article_list_id:$id,start_time:$start_time,end_time:$end_time}) } `;

const UpdateArticleListValidScope = `mutation update {mcm_article_list_valid_scope(_where: {article_list_id:$id}, _set: {start_time:$start_time,end_time:$end_time})}`;

const DeleteArticleListValidScope = `mutation delete {mcm_article_list_valid_scope(_where: {article_list_id: $id})}`;

export {
  GetArticleList,
  CreateArticleList,
  UpdateArticleList,
  ArticleListDetail,
  ArticleListArticle,
  kvConfigQuery,
  GetArticleListValidScope,
  InsertArticleListValidScope,
  UpdateArticleListValidScope,
  DeleteArticleListValidScope,
};
