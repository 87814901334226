//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueQuillEditor from '../vue-quill-edit/editor.vue';
import _Quill from 'quill';
// import Delta from 'quill-delta';
import { OutsideQuestionHtmlTag, outsideQuestionHtml } from '../vue-ueditor/utils';

const Quill = window.Quill || _Quill;
let editorIndex = 0;

export default {
  components: {
    VueQuillEditor,
  },
  props: [
    'editMode', // '':默认样式(用于文章管理/公告), form:表单样式(试卷 投票)
    'content',
    'contentPlaceholder',
    'title',
    'auth',
    'imageList',
    'videoList',
    'audioList',
    'selectedVoteId',
    'selectedExamId',
    'smProgramLink',
    // 中文文本内容最大值j
    'maxTextLength',
  ],
  data() {
    return {
      // content: '<h2>这是内容</h2>',
      editContent: '',
      editorOption: {},
      authVal: '', // 作者
      titleVal: '', // 标题
      isRegisterQuestionsBlot: false,
    };
  },
  computed: {
    editorId() {
      const defaultEditorId = 'editor'; // 默认单实例
      if (this.editMode === 'form' || this.editMode === 'readonly') {
        return defaultEditorId + editorIndex; // 多实例模式
      }
      return defaultEditorId;
    },
    getContentPlaceholder() {
      if (this.editMode === 'readonly') {
        return ' ';
      }
      return this.contentPlaceholder;
    },
    getToolbarContainer() {
      return this.editMode === 'form' ? ['image', 'link'] : undefined;
    },
    fixLinkPosition() {
      return this.editMode === 'form' && this.editMode !== 'readonly';
    },
    hasTitle() {
      return !this.editMode; // 默认true
    },
    hasAuth() {
      return !this.editMode; // 默认true
    },
  },
  watch: {
    imageList(newval) {
      const { quill } = this.$refs.text;
      let index = 0;
      if (quill.getSelection()) {
        index = quill.getSelection().index;
      } else {
        index = quill.getLength();
      }
      const ATTRIBUTES = ['alt', 'height', 'width', 'wx_media_id', 'wx_media_url', 'publicInfo'];

      newval.map((item) => {
        const length = quill.selection.savedRange.index;
        const myimg = Quill.import('formats/image');

        class ImgBlot extends myimg {
          static create(value) {
            const publicInfo = JSON.stringify(value.public_list);
            const agentInfo = JSON.stringify(value.agent_list);
            const node = super.create();
            node.setAttribute('src', value.url); // 设置audio的src属性
            node.setAttribute('mate_id', value.mate_id); // 设置id
            node.setAttribute('style', 'vertical-align: top;'); // 设置图片对齐方式
            node.setAttribute('mate_name', value.mate_name);
            node.setAttribute('mate_data', value.mate_data);
            node.setAttribute('publicInfo', publicInfo); // 设置一个id
            node.setAttribute('agentInfo', agentInfo); // 设置微信应用信息
            return node;
          }
          static formats(domNode) {
            return ATTRIBUTES.reduce(function (formats, attribute) {
              if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
              }
              return formats;
            }, {});
          }

          format(name, value) {
            if (ATTRIBUTES.indexOf(name) > -1) {
              if (value) {
                this.domNode.setAttribute(name, value);
              } else {
                this.domNode.removeAttribute(name);
              }
            } else {
              super.format(name, value);
            }
          }
        }
        ImgBlot.blotName = 'img';
        ImgBlot.tagName = 'img'; // 自定义的标签为img
        Quill.register(ImgBlot);
        quill.insertEmbed(length, 'img', item, 'api');
        index += 1;
      });
      quill.setSelection(index);
    },
    editContent(newval) {
      this.$emit('getContent', newval);
    },
    title: {
      handler(newval) {
        this.titleVal = newval;
      },
      deep: true,
      immediate: true,
    },
    auth: {
      handler(newval) {
        this.authVal = newval;
      },
      deep: true,
      immediate: true,
    },
    content: {
      handler(newval) {
        this.editContent = newval;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    editorIndex = editorIndex + 1;
  },
  mounted() {
    // let quill = this.$refs.text.quill;
    // let length = quill.getLength();
    // console.log(quill.getLength(), "quill.getLength()");
    // quill.insertEmbed(quill.getLength(), "image", "src");
    // console.log(quill.getLength(), "quill.getLength()");
    // quill.formatText(length, quill.getLength(), {
    //   alt: "图片说明",
    //   width: 100,
    // });
  },
  methods: {
    // 自定义blot
    registerQuestionsBlot() {
      // 在mounted里面初始化, 会触发quill的 bug,默认会调用一次create, 参数是个字符串
      if (this.isRegisterQuestionsBlot) {
        return;
      }
      // 引入源码中的BlockEmbed
      // 'blots/block'        : Block,
      // 'blots/block/embed'  : BlockEmbed,
      // 'blots/break'        : Break,
      // 'blots/container'    : Container,
      // 'blots/cursor'       : Cursor,
      // 'blots/embed'        : Embed,
      // 'blots/inline'       : Inline,
      // 'blots/scroll'       : Scroll,
      // 'blots/text'         : TextBlot,

      // 'modules/clipboard'  : Clipboard,
      // 'modules/history'    : History,
      // 'modules/keyboard'   : Keyboard
      const BlockEmbed = Quill.import('blots/block');
      // const TextBlot = Quill.import('blots/text');

      // 定义新的blot类型
      class QuestionsEmbed extends BlockEmbed {
        static create(value) {
          const node = super.create(value);
          outsideQuestionHtml(value, node);
          return node;
        }

        // 返回节点自身的value值 用于撤销操作
        static value(node) {
          return node.innerHTML;
        }
      }
      QuestionsEmbed.blotName = 'OutsideQuestions';
      QuestionsEmbed.tagName = OutsideQuestionHtmlTag;
      // QuestionsEmbed.allowedChildren = [TextBlot];
      Quill.register(QuestionsEmbed, true);
      this.isRegisterQuestionsBlot = true;
    },

    getTitleVal(val) {
      this.$emit('getTitleVal', val);
    },
    getAuthVal(val) {
      this.$emit('getAuthVal', val);
    },
    getTextCount(val) {
      this.$emit('getTextCount', val);
    },
    insertText(text) {
      const { quill } = this.$refs.text;
      if (quill && text) {
        // const delta = quill.clipboard.convert(`<p>${text}</p>`);
        // quill.setContents(delta, 'silent');

        // quill.insertEmbed(quill.selection.savedRange.index, 'p', text);

        // quill.insertText(quill.selection.savedRange.index, '\n');
        // quill.insertText(quill.selection.savedRange.index, text);
        // quill.insertText(quill.selection.savedRange.index, '\n');

        quill.clipboard.dangerouslyPasteHTML(
          quill.selection.savedRange.index,
          `<p><br>${text}</p>`,
          // 'user',
        );
      }
    },
    // 插入外部问卷
    insertOutsideQuestions(data = []) {
      this.registerQuestionsBlot();
      const { quill } = this.$refs.text;
      // quill.clipboard.addMatcher('span', function (node, delta) {
      //   debugger;
      //   return delta.compose(new Delta().retain(delta.length(), { bold: true }));
      // });
      data.forEach((item) => {
        // quill.insertText(quill.selection.savedRange.index, '\n');
        // 和ueditor统一把, 不写两份代码了, 直接插入HTML, 屏蔽自定义组件

        // quill.clipboard.dangerouslyPasteHTML(quill.selection.savedRange.index, '<p><br/></p>');
        // quill.setSelection(quill.selection.savedRange.index + 1);

        quill.insertEmbed(quill.selection.savedRange.index, 'OutsideQuestions', item);
        // 这个方法可以获取光标位置
        // 这里dangerouslyPasteHTML会丢失标签, quill.clipboard.addMatcher('span'没有解决
        // quill.clipboard.dangerouslyPasteHTML(
        //   quill.selection.savedRange.index,
        //   outsideQuestionHtml(item),
        //   // 'user',
        // );
        // quill.setSelection(quill.selection.savedRange.index + 1);
      });
    },
  },
};
