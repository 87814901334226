//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import { breadthFirstTraverse, depthFirstTraverse } from 'nges-common/src/web-mobile/util';
import { execExpression } from '../../../../layout/expression';

const SPLIT_FLAG = '/';

export default {
  name: 'Cascader',
  props: {
    filter: {
      type: [Object],
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
  },
  data() {
    return {
      searchText: '',
      trees: [],
      list: [],
      checkList: [],
      rowsConf: [],
      selectedValues: [],
      preSelectedValues: [],
      page: 1,
      size: 30,
      originSelectedExp: '',
    };
  },
  computed: {
    selectOptions() {
      const { pageOptions = [], searchText } = this;
      const searchTextLowerCase = searchText.toLowerCase();
      const options = searchText
        ? _.cloneDeep(pageOptions).reduce((pre, i) => {
            const { label = '', descs = [] } = i || {};
            if (label) {
              const labelIsMatch = label?.toLowerCase().includes(searchTextLowerCase);
              const descIsMatch = descs.some((desc) => {
                return desc.toLowerCase().includes(searchTextLowerCase);
              });
              if (labelIsMatch || descIsMatch) {
                const regex = new RegExp(`(${searchText})`, 'gi');
                i.htmlLabel = label.replace(
                  regex,
                  '<span style="color: var(--Brand-3);">$1</span>',
                );
                i.descs = descs.map((desc) => {
                  return desc.replace(regex, '<span style="color: var(--Brand-3);">$1</span>');
                });
                pre.push(i);
              }
            }
            return pre;
          }, [])
        : pageOptions;
      return options;
    },
    pageOptions() {
      if (!this.searchText) {
        const totalOptions = this.list || [];
        return totalOptions.slice(0, this.page * this.size);
      }
      return this.filterOptions.slice(0, this.page * this.size);
    },
    filterOptions() {
      if (this.searchText) {
        const totalOptions = this.list || [];
        const filterOptions = totalOptions.filter((item) => {
          const labelIsMatch = item.label?.toLowerCase().includes(this.searchText.toLowerCase());
          const descIsMatch = item.descs?.some((desc) => {
            return desc?.toLowerCase().includes(this.searchText.toLowerCase());
          });
          return labelIsMatch || descIsMatch;
        });
        return filterOptions;
      }
      return [];
    },
    checkStrictly() {
      // 跟小程序保持一致，支持leaf_all
      const leftAll = ['leaf_all'].includes(this?.filter?.type);
      if (leftAll) {
        return false;
      }
      return this.filter?.check_strictly !== undefined ? this.filter.check_strictly : true;
    },
    multiple() {
      return typeof this.filter?.multiple === 'undefined' || this.filter?.multiple;
    },
    checkAll() {
      // 当全选和check_strictly的是否要展示全选按钮，默认展示
      return typeof this.filter?.check_all === 'undefined' || this.filter?.check_all;
    },
    props() {
      return {
        multiple: this.multiple,
        checkStrictly: this.checkStrictly,
      };
    },
  },
  watch: {
    value(val) {
      this.selectedValues = Array.isArray(val) ? val : [];
      let checkList = [];
      if(this.multiple) {
        this.selectedValues.forEach((checked) => {
          Array.isArray(checked) && checkList.push(checked.join(SPLIT_FLAG));
        });
      } else {
        Array.isArray(this.selectedValues) && (checkList = this.selectedValues.join(SPLIT_FLAG));
      }

      this.checkList = checkList;
      this.whereGqlChange(this.selectedValues);
      this.$nextTick(() => {
        this.setCheckedStatus();
      });
    },
    options() {
      this.setTreeData();
    },
  },

  mounted() {
    let { row } = this.filter?.options_from || {};
    if (row) {
      const rowsConf = [];
      rowsConf.push(row);
      while (row.children) {
        rowsConf.push(row.children);
        row = row.children;
      }
      this.rowsConf = rowsConf;
    }
    // 这个mouted会被重新触发，但是下一次进来的where_gql已经被计算过了
    // 只能通过另一个不会被覆盖的属性来记住原始的表达式字符串
    const { where_gql: whereGql, $whereGql } = this.filter || {};
    if (!$whereGql && whereGql) {
      this.filter.$whereGql = whereGql;
    }
    this.originSelectedExp = this.filter.$whereGql;
    this.setTreeData();
  },
  methods: {
    clearCheckedNodes() {
      this.$refs.cascader.clearCheckedNodes();
    },
    setTreeData() {
      const newOptions = _.cloneDeep(this.options);
      breadthFirstTraverse({ children: newOptions }, 'children', (node) => {
        if (node.$height > 1) {
          const { title, value_key: valueKey, key, descs } = this.rowsConf[node.$height - 2] || {};
          node.label = node.label || _.get(node, title) || '';
          node.value = node.value || _.get(node, valueKey || 'id');
          if (descs) {
            const descValues = descs.map((desc) => {
              return _.get(node, desc || '');
            });
            node.descs = descValues;
          }
          if (key && node.value !== 'all') {
            const children = _.get(node, key);
            if (_.isArray(children)) {
              if (children.length) {
                node.children = children;
              }
            } else if (children) {
              node.children = [children];
            }
          }
          if (this.multiple && node?.children?.length && this.checkStrictly && this.checkAll) {
            node.children.unshift({
              label: '全部',
              value: 'all',
            });
          }
        }
      });
      let labelList = [];
      let valueList = [];
      const treeToList = [];
      depthFirstTraverse({ children: newOptions }, 'children', (node) => {
        if (node.$height > 1) {
          if (node.$height >= 2) {
            labelList = labelList.slice(0, node.$height - 2);
            valueList = valueList.slice(0, node.$height - 2);
          }
          if (node.value !== 'all') {
            labelList.push(node.label);
            valueList.push(node.value);
            treeToList.push({
              label: [...labelList].join(SPLIT_FLAG),
              value: [...valueList].join(SPLIT_FLAG),
              descs: node.descs,
            });
          }
        }
      });
      this.trees = newOptions;
      this.list = treeToList;
      this.$emit('get-options', this.list);
    },
    change() {
      let checkList = [];
      if(!this.multiple) {
        checkList = this.selectedValues.join(SPLIT_FLAG);
      } else {
        this.selectedValues.forEach((checked) => {
          checkList.push(checked.join(SPLIT_FLAG));
        });
      }
      this.checkList = checkList;
      this.$nextTick(() => {
        this.setSelectedAll();
        this.setCheckedStatus();
        let value = this.selectedValues;
        let data;
        let list;
        if (!this.multiple) {
          list = this.$refs.cascader.getCheckedNodes()?.[0]?.pathNodes;
          data = (list || []).map((i) => {
            return {
              label: i.label,
              value: i.value,
              pathLabels: i.pathLabels,
              data: i.data,
            };
          });
        } else {
          if (!Array.isArray(value)) {
            return;
          }
          // 节点间相互独立
          if (this.checkStrictly) {
            // 如果级联的选项全部都没有子节点，删除有时候会返回一个一维数组，需要跟选择的时候保持一致的数据结构
            value.forEach((item, index) => {
              if (!Array.isArray(item)) {
                value[index] = [item];
              }
            });
            list = this.$refs.cascader.getCheckedNodes();
            data = list
              .filter((i) => {
                return value.some((ii) => i.value && ii?.[ii.length - 1] === i.value);
              })
              .map((i) => {
                return {
                  label: i.label,
                  value: i.value,
                  pathLabels: i.pathLabels,
                  data: i.data,
                };
              });
          } else {
            list = this.$refs.cascader.getCheckedNodes();
            value = list.map((i) => {
              return i.path;
            });
            data = list.map((i) => {
              return {
                label: i.label,
                value: i.value,
                pathLabels: i.pathLabels,
                data: i.data,
              };
            });
          }
        }
        // const val = this.getChangeVal();
        this.$emit('change', this.selectedValues, data);
        this.$emit('listChange', this.checkList);
        this.whereGqlChange(this.selectedValues);
      });
    },
    setSelectedAll() {
      if (!this.multiple) return;
      const { addItem, deleteItem } = this.getAddOrDelete();
      const { menus } = this.$refs.cascader;
      if (menus) {
        if (addItem) {
          if (addItem.includes('all')) {
            const nodeShouldChecked = [];
            const len = addItem.length;
            for (let i = len - 1; i > 0; i--) {
              if (addItem[i] === 'all') {
                let pNode = [];
                let j = i;
                while (addItem[j - 1] !== 'all' && j - 1 >= 0) {
                  pNode.unshift(addItem[j - 1]);
                  j -= 1;
                }
                const len = pNode.length;
                pNode = pNode.join(SPLIT_FLAG);
                if (pNode) {
                  this.list.forEach((child) => {
                    if (
                      child.value.startsWith(pNode)
                      && child.value.split(SPLIT_FLAG).length === len + 1
                      && !this.checkList.find((c) => c === child.value)
                    ) {
                      this.checkList.push(child.value);
                      nodeShouldChecked.push(child.value);
                      const value = child.value.split(SPLIT_FLAG);
                      const findMenu = menus[value.length - 1].find((menu) =>
                        _.isEqual(menu.path, value),
                      );
                      if (findMenu) {
                        findMenu.checked = true;
                      }
                    }
                  });
                }
              }
            }
            const newNodeShouldChecked = nodeShouldChecked.map((node) => node.split(SPLIT_FLAG));
            this.selectedValues.push(...newNodeShouldChecked);
            this.selectedValues = [...this.selectedValues];
          }
        } else if (deleteItem) {
          if (deleteItem?.includes('all')) {
            let pNode = [];
            let j = deleteItem.length - 1;
            while (deleteItem[j - 1] !== 'all' && j - 1 >= 0) {
              pNode.unshift(deleteItem[j - 1]);
              j -= 1;
            }
            const len = pNode.length;
            pNode = pNode.join(SPLIT_FLAG);
            if (pNode) {
              this.list.forEach((child) => {
                if (
                  child.value.startsWith(pNode)
                  && child.value.split(SPLIT_FLAG).length === len + 1
                ) {
                  const index = this.checkList.findIndex((c) => c === child.value);
                  if (index >= 0) {
                    this.checkList.splice(index, 1);
                  }
                }
              });
              const index = this.checkList.findIndex((c) => c === `${pNode}-all`);
              if (index >= 0) {
                this.checkList.splice(index, 1);
              }
              const selectedValues = [];
              this.checkList.forEach((checked) => {
                selectedValues.push(checked.split(SPLIT_FLAG));
              });
              this.selectedValues = selectedValues;
            }
          }
        }
      }
    },
    getAddOrDelete() {
      let addItem = false;
      let deleteItem = false;
      if (this.preSelectedValues.length < this.selectedValues.length) {
        console.log('add');
        addItem = this.selectedValues.find((i) =>
          this.preSelectedValues.every((ii) => !_.isEqual(ii, i)),
        );
      } else if (this.preSelectedValues.length > this.selectedValues.length) {
        console.log('delete', this.preSelectedValues, this.selectedValues);
        deleteItem = this.preSelectedValues.find((i) =>
          this.selectedValues.every((ii) => !_.isEqual(ii, i)),
        );
      }
      return { addItem, deleteItem };
    },
    setCheckedStatus() {
      if (!this.multiple) return;
      const { menus } = this.$refs.cascader;
      if (menus) {
        const len = menus.length;
        for (let i = len - 1; i >= 0; i--) {
          const menusWithoutAll = menus[i].filter((menu) => menu.value !== 'all');
          const menuIsAll = menus[i].find((menu) => menu.value === 'all');
          if (menuIsAll) {
            if (menusWithoutAll.every((menu) => menu.checked)) {
              menuIsAll.checked = true;
              menuIsAll.indeterminate = false;
              if (!this.selectedValues.some((v) => _.isEqual(v, menuIsAll.path))) {
                this.selectedValues.push(menuIsAll.path);
              }
            } else if (menusWithoutAll.some((menu) => menu.checked)) {
              menuIsAll.indeterminate = true;
              menuIsAll.checked = false;
              const findIndex = this.selectedValues.findIndex((v) => _.isEqual(v, menuIsAll.path));
              if (findIndex >= 0) {
                this.selectedValues.splice(findIndex, 1);
              }
            } else if (menusWithoutAll.every((menu) => !menu.checked)) {
              menuIsAll.indeterminate = false;
              menuIsAll.checked = false;
              const findIndex = this.selectedValues.findIndex((v) => _.isEqual(v, menuIsAll.path));
              if (findIndex >= 0) {
                this.selectedValues.splice(findIndex, 1);
              }
            }
          }
        }
      }
      this.preSelectedValues = [...this.selectedValues];
    },
    load() {
      const pageTatalSize = this.page * this.size;
      if (!this.searchText) {
        const total = this.list.length;
        const noMoreWhenNoFilter = total > pageTatalSize;
        if (noMoreWhenNoFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      } else {
        const filterOptionsSize = this.filterOptions.length;
        const noMoreWhenHadFilter = filterOptionsSize > pageTatalSize;
        if (noMoreWhenHadFilter) {
          this.page += 1;
          console.log('pageTatalSize', pageTatalSize);
        }
      }
    },
    changeCheckbox() {
      let selectedValues = [];
      if(this.multiple) {
        this.checkList.forEach((checked) => {
          selectedValues.push(checked.split(SPLIT_FLAG));
        });
      } else {
        selectedValues = this.checkList.split(SPLIT_FLAG)
      }

      this.selectedValues = selectedValues;
      this.$nextTick(() => {
        this.setCheckedStatus();
      });
      // const val = this.getChangeVal();
      this.$emit('change', this.selectedValues);
      this.$emit('listChange', this.checkList);
      this.whereGqlChange(this.selectedValues);
    },
    expandChange() {
      this.setCheckedStatus();
    },
    search() {},
    getChangeVal() {
      const changeList = [];
      this.checkList.forEach((checked) => {
        const list = checked.split(SPLIT_FLAG);
        list.forEach((item, index) => {
          changeList[index] = changeList[index] || [];
          item !== 'all' && changeList[index].push(item);
        });
      });
      return changeList;
    },
    whereGqlChange: _.debounce(function (val) {
      val = _.cloneDeep(val.filter((i) => !i.includes('all')));
      if (this.multiple) {
        val = val.reduce((pre, item = []) => {
          const index = item.length - 1;
          if (index > -1) {
            pre.push(item[index]);
          }
          return pre;
        }, []);
      } else {
        val = val[val.length - 1];
      }
      const whereGql = execExpression(this.originSelectedExp, {
        t: val,
      });
      this.$emit('whereGqlChange', whereGql);
    }, 200),
    parseLabel(label) {
      return label?.replace(SPLIT_FLAG, '-');
    },
  },
};
