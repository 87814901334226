function variateAction(action, cacheVariates, commit) {
  console.log('cacheVariates', cacheVariates);
  const { action_params: actionParams, action: actionName } = action;
  const { key, value } = actionParams;
  if (actionName === 'SET_VARIATE') {
    commit('action/setCacheVariate', { key, value });
    return { key, value };
  }
  return cacheVariates;
}

export { variateAction };
