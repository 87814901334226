//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CreateShortUrl } from '@/api/article';
import { replaceParams } from '@/libs/utils/util';
import login from '@/utils/login';
import QRCode from 'qrcode';
import { jsPDF } from 'jspdf';
import { debounce } from 'lodash';
const _ = { debounce };

const SENDMESSAGEKEY = 'GET_NODES_FOR_CONTENTLIST_PREVIEW';
const GETMESSAGEKEY = 'SET_NODES_FOR_CONTENTLIST_PREVIEW';

export default {
  name: 'IframeDialog',
  components: {},
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isArticle: {
      type: Boolean,
      default: false, // 设计稿没有这个了
    },
    url: {
      type: String,
      default: '',
    },
    noLogin: {
      // 免登录打开
      type: Boolean,
      default: false,
    },
    exportConfig: {
      type: String,
      default: '',
    },
    // 是否显示导出
    showExport: {
      type: Boolean,
      default: false,
    },
    showQrCode: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    articleName: {
      type: String,
      default: '文章列表预览',
    },
  },
  data() {
    return {
      visibleDialog: false,
      qrcodeUrl: '',
      exportType: '',
      exportJPGLoading: false,
      exportPDFLoading: false,
      exportConfigArr: [],
    };
  },
  computed: {
    redirectUri() {
      if (!this.visibleDialog) {
        // 每次可见都刷新下链接, 以防缓存
        return '';
      }

      let redirectUri = this.url;
      if (redirectUri && redirectUri.indexOf('timestamp=') === -1) {
        redirectUri = replaceParams({ timestamp: Date.now() }, redirectUri);
      }

      if (!this.noLogin || !redirectUri) {
        return redirectUri;
      }

      redirectUri = replaceParams(
        {
          uin: login.getUin(),
          token: login.getToken(),
          type: 'staff', // 都以员工身份授权
          tokentype: 'weixin',
        },
        redirectUri,
      );

      return redirectUri;
    },
    dialogWidth() {
      if (this.width) {
        return this.width;
      }
      if (this.custom) {
        return '968px';
      }
      return '700px';
    },
  },
  watch: {
    visible: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.visibleDialog = newValue;
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('message', this.debounceGetPreviewDOM);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.debounceGetPreviewDOM, true);
  },
  mounted() {
    this.init();
  },
  methods: {
    // 成成锻炼
    async creatShortLink(l) {
      try {
        const link = await CreateShortUrl({
          original_url: l,
        });
        return link.url;
      } catch (e) {
        return '';
      }
    },
    debounceGetPreviewDOM: _.debounce(function (event) {
      return this.getPreviewDOM(event);
    }, 2000),
    getPreviewDOM(event) {
      if (event.data?.type === GETMESSAGEKEY) {
        console.log('接收DOM元素节点：', event.data, this.exportType);
        if (event.data && event.data.data !== false) {
          try {
            if (this.exportType === 'JPG') {
              const link = document.createElement('a');
              link.download = `${this.articleName}.jpg`;
              link.href = event.data.data;
              link.click();
            } else if (this.exportType === 'PDF') {
              let orientation = 'portrait';
              if (event.data.width >= event.data.height) {
                orientation = 'landscape';
              }
              // eslint-disable-next-line new-cap
              const pdf = new jsPDF(orientation, 'px', [event.data.width, event.data.height]);
              pdf.addImage(
                event.data.data,
                'PNG',
                0,
                0,
                event.data.width,
                event.data.height,
                '',
                'FAST',
              );
              pdf.save(`${this.articleName}.pdf`);
            }
          } catch (error) {
            console.log(error);
          }
        }
        this.exportJPGLoading = false;
        this.exportPDFLoading = false;
      }
    },
    exportPDF() {
      this.exportType = 'PDF';
      this.exportPDFLoading = true;
      this.sendPostMassageInfo();
    },
    exportJPG() {
      this.exportType = 'JPG';
      this.exportJPGLoading = true;
      this.sendPostMassageInfo();
    },
    sendPostMassageInfo() {
      const iframe = document.getElementsByClassName('iframe_box')[0];
      iframe.contentWindow.postMessage({ type: SENDMESSAGEKEY }, '*');
    },
    closeFun() {
      this.$emit('update:visible', false);
    },
    async init() {
      this.exportConfigArr = this.exportConfig.split(',');
      // const appid = this.publishedAppList.find((i) => i.type === 1 || i.type === 2)?.appid;
      // const domain =
      //   this.config.env === 'development' ? 'testh5.nges.qq.com' : this.config.mobileDomain;
      // console.log(domain);
      // const originUrl = `${location.protocol}//${domain}/pages/article/detail/index?id=${
      //   this.previewId
      // }&appid=${appid}&type=tourist&ispreview=true&datafrom=${this.dataFrom}${
      //   this.showExport ? '&showExport=1' : ''
      // }`;
      // 方便本地调试
      // const originUrl = `http://localhost:8081/mini/pages/article/detail/index?id=${
      //   this.previewId
      // }&appid=${appid}&type=tourist&ispreview=true&datafrom=${this.dataFrom}${
      //   this.showExport ? '&showExport=1' : ''
      // }`;
      this.createQrCodeUrl();
    },

    async createQrCodeUrl() {
      if (!this.showQrCode) {
        return;
      }
      const originUrl = this.redirectUri;

      let _url = originUrl;
      _url = replaceParams(
        {
          uin: login.getUin(),
          token: login.getToken(),
          type: 'staff', // 都以员工身份授权
          tokentype: 'weixin',
          timestamp: new Date().getTime(),
        },
        originUrl,
      );
      const _link = await this.creatShortLink(_url);
      QRCode.toDataURL(_link)
        .then((url) => {
          this.qrcodeUrl = url;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
