//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThinkingComp from './thinking';
import { isArray, trim, includes, isString } from 'lodash';
import { customLogin } from 'nges-common/src/login';
import TextSourceBottom from './text-source-bottom.vue';
const ALLOW_OPEN_FILE_TYPE = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];
// 默认消息类型
export default {
  components: {
    ThinkingComp,
    TextSourceBottom,
  },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  options: { styleIsolation: 'shared' },
  data() {
    return {
      expandSwitch: false,
      isExpand: false,
      isContentExpand: [],
      preImg:
        'https://cdn.nges.tencent.com/nges_1/22504202/9713f96e-0091-4ca5-a702-67a132c1eaac.png',
    };
  },
  computed: {
    loading() {
      return !!this.message?.request?.loading;
    },
    msgParams() {
      return this.message.params || {};
    },
    canCopy() {
      // 能复制
      return (this.config?.can_copy && this.message.can_copy) || false;
    },
    botConfig() {
      return this.message?.request?.bot_config;
    },
    question() {
      return this.message?.request?.question || '';
    },
    reply() {
      // reply: [ {text } ]
      return this.message?.response?.reply || [];
    },
    source() {
      const source = this.message?.response?.source || [];
      if (source.length) {
        source.forEach((item) => {
          item.contents = this.getDetailContent(item.content, item.title);
          item.canOpen = this.verifyFileType(item.link || item.source_url);
        });
      }
      return source;
    },
  },
  mounted() {
    this.setExpand();
  },
  methods: {
    changeToRichTextNodes(text) {
      text = text.replace(/\*\*(.*?)\*\*/g, '<strong>**$1**</strong>');
      // 未来有其他样式要求 测试使用 style的方式可以正常写入样式
      // text = text.replace(/（(.*?)）/g, '<span style="color:blue">($1)</span>');
      // console.log('%c Line:118 🥖 text', 'color:#42b983', text);
      return `<div >${text}</div>`;
    },
    setExpand() {
      // this.$nextTick(() => {
      //   uni
      //     .createSelectorQuery()
      //     .in(this)
      //     .select('.msg-wrap')
      //     .boundingClientRect((data) => {
      //       if (data && data.height) {
      //         if (data.height > EXPAND_MAX_HEIGHT_PX) {
      //           this.expandSwitch = true;
      //         }
      //       }
      //     })
      //     .exec();
      // });
    },
    toggleExpand() {
      this.isExpand = !this.isExpand;
    },
    async toDoc(item) {
      console.log('toDoc', item);
      const fileId = item.file_id;
      const extraParams = JSON.parse(
        typeof item.extra_params === 'string' || item.extra_params instanceof String
          ? item.extra_params
          : '{}',
      );
      if (fileId && !item.source_url) {
        this.g_tourl({
          wxaurl: `/pages/chatbot/meterial?id=${fileId}`,
        });
      } else if (item.source_url && extraParams.content_type === 1) {
        this.g_tourl({
          wxaurl:
            `/${item.source_url}${encodeURIComponent(`&appid=${customLogin.getAppid()}`)}` +
            '&canShare=true&shareAppMessage=true',
        });
      } else if (item.source_url && extraParams.content_type === 2) {
        this.g_tourl({
          wxaurl: `/${item.source_url}`,
        });
      }
    },
    toggleContentExpand(idx) {
      const expand = this.isContentExpand?.[idx] || false;
      this.$set(this.isContentExpand, idx, !expand);
    },
    getDetailContent(content, title) {
      const arr = isArray(content) ? content : [content];
      const contents = [];
      arr.forEach((item) => {
        let text = trim(item.text || '');
        if (title) {
          const filterStr = `${title}：`;
          if (text.indexOf(filterStr) === 0) {
            text = text.substring(filterStr.length);
          }
          // text = text.replace(new RegExp(`^${title}：`), '');
        }
        text = trim(text);
        if (text) {
          contents.push(text);
        }
      });
      return contents;
    },
    verifyFileType(url = '') {
      if (!url || !isString(url)) {
        return false;
      }
      
      
    },
  },
};
