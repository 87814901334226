//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { execExpressionDeep } from '../../../../layout/expression';
import { get, isEmpty, cloneDeep, isObject, debounce } from 'lodash';
import LayoutDetail from '../detail';
import LayoutMutation from '../mutation';
import LayoutList from '../full-list';
import { mapGetters, mapState } from 'vuex';
import { getLayoutJson } from 'nges-common/src/layout/queryWeb';
import Action from '../../action';
import Premission from '../../permission';
import TabMixin from '../mixin-tab/tabMixin';
import RemoteCustomComponent from '../../remote-custom-component';
import Search from './components/search';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';

const QUERY_TAB_INDEX_KEY = 'tab_index';
const QUERY_TAB_KEY = 'tab_key';
// 当前组件的slot，不传入子组件中
const CURRENT_COM_SLOT = ['top', 'bottom'];

export default {
  components: {
    LayoutDetail,
    LayoutMutation,
    LayoutList,
    RemoteCustomComponent,
    Search,
  },
  mixins: [Action, Premission, TabMixin, TrackingCompMixin],
  props: {
    beforeLeave: {
      type: Function,
      default: null,
    },
    beforeSubmit: {
      type: Function,
      default: null,
    },
    lifeCycle: {
      type: Object,
    },
    json: {
      type: [Object, Array],
      default: null,
    },
    layout: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    /* 编辑布局和详情布局额外参数 */
    mutationLayout: {
      type: String,
      default: '',
    },
    // 是否切换tab时刷新子页面，默认为false
    isChangeRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionLoading: false,
      tabJson: {},
      tabIndex: null,
      tabOpenedFlag: {},
      targetId: this.$route.query.id || null,
      tabs: [],
      tabCofJson: null,
      tabKey: '',
    };
  },
  computed: {
    ...mapState('user', {
      menuList: 'menuList',
    }),
    ...mapGetters('user', {
      layoutJson: 'getLayoutJson',
    }),
    tabsFromJson() {
      if (isEmpty(this.tabJson)) return [];
      // 根据权限获取菜单
      return (this.tabJson || [])
        .filter(({ hidden }) => !this.execExpression(hidden))
        .filter(this.mixinFilterPermission);
    },
    comSlots() {
      return Object.entries(this.$slots).filter((slot) => !CURRENT_COM_SLOT.includes(slot[0]));
    },
    comScopeSlots() {
      return Object.entries(this.$scopedSlots).filter(
        (slot) => !CURRENT_COM_SLOT.includes(slot[0]),
      );
    },
    expParamsObj() {
      return {
        p: {
          ...this.params,
        },
      };
    },
  },
  watch: {
    json(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.initTab();
      }
    },
    layout() {
      // 权限加载完成后再进行更新操作
      if (!this.mixinPermissionLoading) {
        this.initTab();
      }
    },
    tabsFromJson(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.tabs = cloneDeep(this.tabsFromJson);
        this.initTab();
      }
    },
  },
  methods: {
    mixinPermissionLoad() {
      this.initTab();
    },
    execExpression(obj) {
      let params = obj;
      if (isObject(obj)) {
        params = cloneDeep(obj);
      }
      const result = execExpressionDeep(params, {
        p: {
          ...this.params,
        },
      });
      return result;
    },
    handleActive(tab) {
      const tabIndex = String(tab.name);
      const tabKey = tab.key;
      if (tab.key) {
        this.setRouterPath(tabKey);
        this.$nextTick(() => {
          // 如果存在beforeLeave钩子，则这里会导致二次触发beforeLeave逻辑
          if (this.beforeLeave === null) {
            this.tabKey = tabKey;
          }
          this.$set(this.tabOpenedFlag, tabKey, true);
          // 如果在页面加载完成后马上调用handleActive改变tab的状态的话这里会因为加载中而获取不到值 需要做兜底处理
          const filteredTabs = this.tabs.filter((tab) => tab.tab_key === tabKey);
          if (filteredTabs.length > 0) {
            this.$emit('tab-change', filteredTabs[0]);
          }
          this.tabTracking();
        });
      } else {
        this.setRouterPath(parseInt(tabIndex));
        this.$nextTick(() => {
          // 如果存在beforeLeave钩子，则这里会导致二次触发beforeLeave逻辑
          if (this.beforeLeave === null) {
            this.tabIndex = tabIndex;
          }
          this.$set(this.tabOpenedFlag, tabIndex, true);
          // 如果在页面加载完成后马上调用handleActive改变tab的状态的话这里会因为加载中而获取不到值 需要做兜底处理
          this.$emit('tab-change', this.tabs[tab.name]);
          this.tabTracking();
        });
      }
    },
    tabTracking: debounce(function () {
      if (this.tabKey) {
        const { tabKey } = this;
        const filteredTabs = this.tabs.filter((tab) => tab.tab_key === tabKey);
        const tabConf = filteredTabs.length > 0 ? filteredTabs[0] : null;
        this.__compTracking(
          {
            event_type: 'view',
            event_id: `view_Event`,
            event_name: `${this.execExpression(tabConf.label) || ''}浏览`,
          },
          this.execExpression(tabConf.tracking),
        );
      } else {
        const tabIdx = this.tabIndex;
        const tabConf = this.tabs[tabIdx];
        this.__compTracking(
          {
            event_type: 'view',
            event_id: `view_Event`,
            event_name: `${this.execExpression(tabConf.label) || ''}浏览`,
          },
          this.execExpression(tabConf.tracking),
        );
      }
    }, 100),
    handleRemove(name) {
      const removeTabIndex = parseInt(name);
      this.tabs.splice(removeTabIndex, 1);
      this.$set(this.tabOpenedFlag, name, false);
      this.$nextTick(() => {
        this.handleActive({ name: '0' });
      });
    },
    setRouterPath(index) {
      if (this.tabKey) {
        const filteredTabs = this.tabs.filter((tab) => tab.tab_key === this.tabKey);
        const tabConf = filteredTabs.length > 0 ? filteredTabs[0] : null;
        if (tabConf?.path) {
          const path = this.execExpression(tabConf.path);
          const routerQuery = cloneDeep(this.$router?.query || {});
          this.$router.push({
            query: { ...routerQuery, path },
          });
        }
      } else {
        const tabConf = this.tabs[index];
        if (tabConf?.path) {
          const path = this.execExpression(tabConf.path);
          const routerQuery = cloneDeep(this.$router?.query || {});
          this.$router.push({
            query: { ...routerQuery, path },
          });
        }
      }
    },
    // 获取tab json配置
    getTabJson() {
      const json = this.json ? this.json : this.layoutJson;
      this.tabJson = this.layout ? get(json, this.layout) : json;
      this.tabCofJson = this.layoutJson?.['tab-layout-conf'];
    },
    // 获取单个tab的内容json
    getTabPaneJson(tab) {
      // const tab = this.tabs[parseInt(this.tabIndex)];
      if (tab?.json) {
        return tab.json;
      }
      if (tab?.path) {
        return getLayoutJson(this.menuList, tab.path);
      }
      return {};
    },
    initTab() {
      this.getTabJson();
      const queryTabIndex = this.$route.query[QUERY_TAB_INDEX_KEY];
      const queryTabKey = this.$route.query[QUERY_TAB_KEY];
      if (queryTabKey) {
        this.tabIndex = String(this.tabsFromJson.findIndex((tab) => tab.tab_key === queryTabKey));
        if (Number(this.tabIndex) === -1) {
          this.tabIndex = '0';
          this.tabOpenedFlag = { [this.tabIndex]: true };
          this.handleActive({ key: this.tabIndex });
        }
        this.tabKey = queryTabKey;
        this.tabOpenedFlag = { [this.tabKey]: true };
        this.handleActive({ key: this.tabKey });
      } else {
        // 如果没有tab_key参数,走原来的逻辑
        let tabIndex = '0';
        if (
          parseInt(queryTabIndex) == queryTabIndex &&
          this.tabs.length &&
          parseInt(queryTabIndex) <= this.tabs.length
        ) {
          tabIndex = String(parseInt(queryTabIndex) - 1);
        }
        if (this.tabs.length) {
          // 如果在页面加载完成前就调用了handleActive调整当前tab 就保留当前的状态
          if (this.tabIndex !== '0') {
            // this.tabPaneJson = this.getTabPaneJson();
          } else {
            this.tabIndex = tabIndex;
            this.tabOpenedFlag = { [tabIndex]: true };
            // 如果tab_index有找到新参数 就抛出事件到外层组件
            if (tabIndex !== '0') {
              this.handleActive({ name: tabIndex });
            }
            // this.tabPaneJson = this.getTabPaneJson();
          }
        }
      }
      this.$nextTick(() => {
        this.tabTracking();
      });
    },
    getCurrentList() {
      const el = this.$refs['layout-list'].filter((i) => i.$el.id === this.tabIndex);
      return el.length ? el[0] : undefined;
    },
    getTableData() {
      this.getCurrentList()?.getTableData();
    },
    async m_customAction(action) {
      /**
       * 若自身父节点已经存在mix_tab组件了，则自己不处理，由父mixin_tab处理，保证在最外层的tab打开
       * 如：详情套了带mixtab的列表，列表中点了打开tab的按钮，最后需在最外层详情布局中打开tab。
       * */
      const tab = await this.customAction(action);
      if (tab) {
        const newTab = {
          label: tab?.tabTitle || tab?.json?.title || tab?.json?.layoutName,
          json: tab?.json,
          id: tab.targetId,
          layout_type: tab.type,
          closable: true,
        };
        this.tabs.push(newTab);
        this.handleActive({ name: this.tabs.length - 1 });
      }
      return action;
    },
    reload(ignoreID) {
      (this.$refs['layout-list'] || []).forEach((ref) => {
        const { id } = ref.$el;
        if (id.toString() === ignoreID.toString()) return;
        ref.getTableData && ref.getTableData(); // 列表刷新
      });
      (this.$refs['layout-detail'] || []).forEach((ref) => {
        const { id } = ref.$el;
        if (id.toString() === ignoreID.toString()) return;
        ref.reload && ref.reload(); // 列表刷新
      });
    },
    // 重写tab的beforeLeave钩子方法，第三个参数传入过滤了hidden的tabs
    myBeforeLeave(newName, oldName) {
      if (this.beforeLeave) {
        return this.beforeLeave(newName, oldName, this.tabs);
      }
      return true;
    },
  },
};
