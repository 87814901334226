

// eslint-disable-next-line
import { getPageConfig as getPageConfigWeb } from '../../../../web/utils/common';
// eslint-disable-next-line
import globalEventHandlesWeb from './webGlobalEventHandles';


const events = {
  // 用于存储系统全局标准方法
  global: {
    
    
    ...globalEventHandlesWeb,
    
  },
  // 业务自带的监听方法
  custom: {},
};
export async function addIframeMessageListener() {
  let getPageConfig;
  
  getPageConfig = getPageConfigWeb;
  
  const iframeAllowHost = await getPageConfig('web_iframe_allow_host', 'array');
  window.addEventListener('message', function (event) {
    const origin = event.origin.replace(/^https?:\/\//i, '');
    if (!iframeAllowHost.includes(origin)) {
      return;
    }
    try {
      const data = JSON.parse(event.data) || {};
      const { action } = data;
      const func = events.global[action] || events.custom[action];
      if (!func) {
        throw new Error(`receive iframe message error: action "${action}" is not exist`);
      }
      func(data.params);
    } catch (e) {
      console.error(e);
    }
  });
}

export function addIframeMessageHandleEvent(action, func, type) {
  events[type === 'global' ? 'global' : 'custom'][action] = func;
}

export function remove0IframeMessageHandleEvent(action, type) {
  type = type === 'global' ? 'global' : 'custom';
  if (action) {
    delete events[type][action];
  }
}
