import { execExpressionDeep } from '../../layout/expression';

const emitAfterAction = {};

export default {
  created() {
    this.$oldEmit = this.$emit;
    this.$emit = this.emitListener;
  },
  methods: {
    emitListener(name, ...prop) {
      try {
        const pages = getCurrentPages();
        const route = pages[pages.length - 1]?.options || {};
        if (route[`emit_after${name}`]) {
          const action = emitAfterAction[route[`emit_after${name}`]];
          if (action) {
            this.m_actionHandle(execExpressionDeep(action, this.expParamsObj));
            // 保证下次读取route读取到了实时query
            delete route[`emit_after${name}`];
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.$oldEmit(name, ...prop);
    },
    addEmitListener(action) {
      let id = new Date().getTime();
      while (emitAfterAction[id]) id += 1;
      emitAfterAction[id] = action;
      return id;
    },
  },
};
