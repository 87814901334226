import request from '../../request';
import { ACTION_CMD_PREFIX } from './constant';
import { isString } from 'lodash';

function cmdActionRequest(action) {
  return new Promise((resolve, reject) => {
    const { cmd, action_payload: actionPayload = {}, payload } = action;
    const fullCmd = cmd.indexOf('/') > -1 ? cmd : `${ACTION_CMD_PREFIX}${cmd}`;
    request(fullCmd, payload || actionPayload)
      .then((resp) => {
        let data = resp;
        if (isString(resp)) {
          // 处理返回payload为字符串的场景，对齐做JSON Parse操作
          try {
            data = JSON.parse(resp.toString());
          } catch (e) {}
        }
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export { cmdActionRequest };
