//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    errorNumber: {
      type: Number,
      default: 1,
    },
    errorCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    errorTips() {
      return `有 ${this.errorCount} 个字段填写错误，请修正`;
    },
  },
  methods: {
    handleClickPreviousNextBtn(actionName) {
      this.$emit(`previous-next-error`, {
        actionName,
        errorNumber: this.errorNumber,
        errorCount: this.errorCount,
      });
    },
  },
};
