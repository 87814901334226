import { render, staticRenderFns } from "./topCascader.vue?vue&type=template&id=3974d410&scoped=true&"
import script from "./topCascader.vue?vue&type=script&lang=js&"
export * from "./topCascader.vue?vue&type=script&lang=js&"
import style0 from "./topCascader.vue?vue&type=style&index=0&id=3974d410&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../nges-web/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3974d410",
  null
  
)

export default component.exports