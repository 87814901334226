import { queryUserInfo } from '@/graphql/api/user.js';
import login from '@/utils/login';
import { isExpression, execExpression } from 'nges-common/src/layout/expression';
import store from '@/store';
import { DataService } from 'nges-common/src/web/utils/api';
import { calVariables } from '@/graphql/util';
import { isObject, isArray, isString } from 'lodash';
import { getKvConfigs } from '@/graphql/api/meeting';

// 获取文章通用mcm_article_config配置
let gArticleConfig;
export async function GetArticleConfig() {
  if (!gArticleConfig) {
    try {
      const config = await getKvConfigs({
        keys: ['mcm_article_config'],
      }).catch(() => null);
      gArticleConfig = JSON.parse(config?.kv?.[0]?.value || '{}');
    } catch (error) {
      console.log(error);
      if (!gArticleConfig) {
        gArticleConfig = {};
      }
    }
  }
  return Promise.resolve(gArticleConfig);
}

let gWebLayoutConfig;
export async function GetWebLayoutConfig() {
  if (!gWebLayoutConfig) {
    try {
      const config = await getKvConfigs({
        keys: ['mcm_web_layout'],
      }).catch(() => null);
      gWebLayoutConfig = JSON.parse(config?.kv?.[0]?.value || '{}');
    } catch (error) {
      console.log(error);
      if (!gWebLayoutConfig) {
        gWebLayoutConfig = {};
      }
    }
  }
  return Promise.resolve(gWebLayoutConfig);
}

/**
 * 根据文章的权限列表，返回游客表示字符串，用于拼链接
 * @param identityTag 文章权限列表
 */
export const getTouristFlag = (identityTag) => {
  let flag = '';
  // const articleIdentityTag = identityTag ? identityTag.split(',') : [];
  // if (articleIdentityTag.includes('tourist')) {
  if (identityTag === 'tourist') {
    flag = '&type=tourist';
  }
  return flag;
};

/**
 * 返回adtag，用于拼链接
 * // appid获取 &appid=${adtag.official_account.appid}
 */
export const getAdtagFlag = (adtag) => {
  let flag = '';
  if (adtag.official_account) {
    flag = `&adtag=${encodeURIComponent(adtag.name)}`;
  }
  return flag;
};

// user_info 额外信息
let gUserInfo;
export const getAdditionUserInfo = async () => {
  try {
    const loginUin = login.getUin();
    if (gUserInfo?.uin && gUserInfo.uin === loginUin) {
      return gUserInfo;
    }
    // const whereQgl = store.getters['appConfig/getMcmIds']?.producer_gql;
    const additionUserInfo = store.getters['appConfig/getMcmIds']?.additionUserInfo || [];
    // business_unit
    gUserInfo = (
      await queryUserInfo({
        uin: loginUin,
        // where_qgl: whereQgl,
        other_keys: additionUserInfo,
      }).catch(() => null)
    )?.user_info?.[0];
  } catch (error) {
    console.log(error);
  }

  return gUserInfo || {};
};

// 针对通用  MCM进行事件扩展  使用doMCMAdditionAction.call(this, actionData)
export async function doMCMAdditionAction(actionData) {
  if (!this) {
    console.error('请使用 doMCMAdditionAction.call(this, actionData) ');
    return;
  }
  if (!actionData) {
    return;
  }

  const { action } = actionData;
  const actionParams = actionData.action_params || actionData.actionParams || {};
  const rowItem = actionParams._item;

  // console.log('哈哈哈', action, actionData);

  if (action === 'showToast') {
    // 通用已支持 action: "MSG: 提示语", 可以弃用了
    // 提示性弹框
    let message;
    let type;

    if (actionParams.error) {
      message = actionParams.error;
      type = 'error';
    } else if (actionParams.success) {
      message = actionParams.success;
      type = 'success';
    } else if (actionParams.warning) {
      message = actionParams.warning;
      type = 'warning';
    } else {
      message = actionParams.message || actionParams.content || actionParams.info;
      type = 'info';
    }

    if (isExpression(message)) {
      // 正常走不到这里 备用
      message = execExpression(message, {
        action,
        actionParams,
        t: rowItem,
      });
    }

    this.$message({
      dangerouslyUseHTMLString: !!actionParams.use_html_string,
      duration: actionParams.duration || 3000,
      message,
      type,
    });
  } else if (action === 'MCM_COPY_OBJECT') {
    // MCM 元数据拷贝(废弃 -- 代码已经移入通用 使用COPY_OBJECT)
    let { copy_id: id } = actionParams;
    const { copy_object: object } = actionParams;
    if (id && isExpression(id)) {
      id = execExpression(id, { t: rowItem, $item: rowItem });
    }
    const res = await DataService({
      query: calVariables(`query {${object} (_where:{ id:$id }){_all}}`, { id }),
    }).catch((e) => {
      this.$message.error(e.message);
      return null;
    });
    const originObjectData = res?.[object]?.[0];
    if (!originObjectData) {
      this.$message.error('复制失败');
      return;
    }
    let { ignore_fields: ignoreFields, payload } = actionData.copy_action_callback || {};
    // 此处只增不减(业务逻辑也可以处理)
    const systemIgnoreFields = [
      'id',
      'owner',
      'update_by',
      'update_time',
      'create_by',
      'create_time',
      'version',
      'delete_time',
    ];
    if (ignoreFields && isExpression(ignoreFields)) {
      ignoreFields = execExpression(ignoreFields, {
        t: {
          $item: originObjectData,
          $ignoreFields: [...systemIgnoreFields],
        },
      });
    } else {
      ignoreFields = [...systemIgnoreFields, ...(ignoreFields || [])];
    }

    // 写入真实字段
    if (payload && isExpression(payload)) {
      payload = execExpression(payload, {
        t: {
          $item: originObjectData,
          $ignoreFields: [...ignoreFields],
        },
      });
    } else {
      payload = { ...originObjectData, ...(payload || {}) };
    }

    const mutationFields = [];
    Object.keys(payload).forEach((key) => {
      // 忽略掉配置的字段
      if (ignoreFields.includes(key)) {
        return;
      }
      mutationFields.push(`${key}: $${key}`);
    });

    const copyRes = await DataService({
      query: calVariables(
        `mutation insert {
        ${object}(_values: {${mutationFields.join(',')}
      }) }`,
        payload,
      ),
    }).catch((e) => {
      this.$message.error(e.message);
      return null;
    });
    if (copyRes?.[object]?.[0]) {
      this.$message.success('复制成功');

      // @ts-ignore
      Object.values(this.$refs).forEach((ref) => {
        // const getTableData = vueThis.$refs.tab?.getTableData || vueThis.$refs.list?.getTableData;
        if (typeof ref?.getTableData === 'function') {
          ref.getTableData(true);
        }
      });

      // actionData.action_callback = { action: 'RELOAD' };
    }
  }
}

// 执行表达式
export function doExecExpression(obj, expParamsObj) {
  if (!obj || obj.__not_exec_expression__) {
    return obj;
  }
  if (isString(obj)) {
    return execExpression(obj, expParamsObj);
  }
  if (isArray(obj)) {
    const newArray = [];
    obj.forEach((item) => {
      newArray.push(doExecExpression(item, expParamsObj));
    });
    return newArray;
  }
  if (isObject(obj)) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = doExecExpression(obj[key], expParamsObj);
    });
    return newObj;
  }

  return obj;
}
