import { getAdtags } from '@/api/adtags';

export default {
  namespaced: true,
  getters: {
    getAdtags(state) {
      return state.adtags;
    },
  },
  state: {
    adtags: null,
  },
  mutations: {
    setAdtags(state, data) {
      state.adtags = data;
    },
  },
  actions: {
    // force 是否强制重新请求
    async requestAdtags(context, force = false) {
      if (!force && context.state.adtags) {
        return context.state.adtags;
      }
      return new Promise((resolve, reject) => {
        getAdtags().then(
          (res) => {
            const _adtags = res.mcm_adtags?.filter((item) => {
              return item.official_account && +item.is_trace === 1;
            });
            context.commit('setAdtags', _adtags);
            resolve();
          },
          (e) => reject(e),
        );
      });
    },
  },
};
