//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 默认消息类型
export default {
  props: {
    questionEntry: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: 'message', // float message 两种样式
    },
  },
  data() {
    return {
      isFastEntryVisible: false,
      isFastEntryVisibleAnimated: false,
    };
  },
  computed: {
    entryData() {
      return this.questionEntry || {};
    },
    questionList() {
      return this.entryData.groups || [];
    },
    isFloatMode() {
      return this.mode === 'float';
    },
  },
  mounted() {},
  methods: {
    handleClickFloatButton() {
      this.$emit('click-float-button', !this.isFastEntryVisible);

      if (this.isFastEntryVisible) {
        this.closeFastEntry();
      } else {
        this.openFastEntry();
      }
    },
    openFastEntry() {
      this.isFastEntryVisible = true;
      setTimeout(() => {
        this.isFastEntryVisibleAnimated = true;
      }, 50);
    },
    closeFastEntry() {
      this.isFastEntryVisibleAnimated = false;
      setTimeout(() => {
        this.isFastEntryVisible = false;
      }, 300);
    },
    askQuestion(item) {
      this.$emit('ask', { mode: this.mode, item });
    },
  },
};
