//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import { execExpressionDeep } from 'nges-common/src/layout/expression';
import { layoutRequest } from 'nges-common/src/layout/layoutRequest';

const _ = { cloneDeep };

export default {
  name: 'MiniDashboardChild',
  props: {
    json: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: {},
      item: {},
    };
  },
  computed: {
    expParamsObj() {
      return {
        t: {
          ...this.dataSource,
        },
        p: this.params,
      };
    },
  },
  methods: {
    init() {
      const json = _.cloneDeep(this.json);
      json.ignore_id = true;
      if (json.cmd || json.object) {
        return layoutRequest(json, this.expParamsObj)
          .then((value) => {
            if (json.object) {
              const data = {
                list: value.formattedValues,
                aggregate: value.aggregate,
              };
              this.dataSource = data;
              if (json.key) {
                return {
                  key: json.key,
                  value: data,
                };
              }
              return undefined;
            }
            this.dataSource = value.remoteValues;
            if (json.key) {
              return {
                key: json.key,
                value: value.remoteValues,
              };
            }
            return undefined;
          })
          .catch((e) => {
            console.log(e);
            return undefined;
          });
      }
      this.dataSource = {};
      return Promise.resolve(undefined);
    },
    render() {
      this.item = execExpressionDeep(_.cloneDeep(this.json.render), this.expParamsObj);
    },
  },
};
