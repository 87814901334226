import { getPathOpts } from '../../layout/util';
import { isExpression as isExp, execExpression } from '../../layout/expression';
import {
  isString,
  includes,
  isObject,
  forEach,
  isNil,
  has,
  set,
  isPlainObject,
  cloneDeep,
  endsWith,
  isArray,
} from 'lodash';
import { cmdActionRequest } from './cmd-action';
import { graphqlActionRequest } from './graphql-action';
import {
  CONFIRM_ACTION,
  NAVIGATION_ACTION,
  NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH,
  NOTICE_ACTION,
  ACTION_MSG_PREFIX,
  COMMON_GRAPHQL_ACTION,
  SELECT_CONFIRM,
  VARIATE_ACTION,
} from './constant';
import { confirmAction } from './confirm-action';
import { setPageData } from './page-data';
import { replaceParams } from '../../web-mobile/util';
import { noticeAction } from './notice-action';
import { msgAction } from './msg-action';
import { clipboardAction } from './clipboard-action';
import MemoryStoreMixin from '../memory-store/memory-store-mixin';
import EmitAction from '../emit';
import { globalSharePopup } from './share-popup/util';
import { selectConfirmDialog } from './select-confirm-dialog/util';
import { globalPhoneCall } from './phone-call/util';
import { variateAction } from './variate-action';
import { globalConfig as globalDetailDialog } from './detail-dialog/util';

import { mapGettersWrap } from '@tencent/vuex-dynamic-store';
// import { applyAction } from './approval-action';
const _ = {
  isString,
  includes,
  isObject,
  forEach,
  isNil,
  has,
  set,
  isPlainObject,
  cloneDeep,
  endsWith,
};

function isNavigationAction(action) {
  const { action: actionName } = action;
  return (
    _.isString(action?.action_params?.object) &&
    _.isString(actionName) &&
    _.includes(NAVIGATION_ACTION, actionName)
  );
}

function isNoticeAction({ action }) {
  return _.isString(action) && _.includes(NOTICE_ACTION, action);
}

function isMsgAction({ action }) {
  return _.isString(action) && action.indexOf(ACTION_MSG_PREFIX) === 0;
}

function isCommonGqlAction({ action }) {
  return _.isString(action) && _.includes(COMMON_GRAPHQL_ACTION, action);
}

function isConfirmAction({ action }) {
  return _.isString(action) && _.includes(CONFIRM_ACTION, action);
}
function isSelectConfirmAction(action) {
  action = action?.action;
  return _.isString(action) && _.includes(SELECT_CONFIRM, action);
}
function isVariateAction({ action }) {
  return _.isString(action) && _.includes(VARIATE_ACTION, action);
}
// 兼容loading和toast同时发起时的神奇冲突问题。
let loadingNowSeq = 0;
let loadingSeq = 1;
function hideLoading(seq) {
  setTimeout(() => {
    if (!seq || seq === loadingNowSeq) {
      uni.hideLoading();
    }
  });
}

// 重写解析表达式方法，不解析action_callback
export function execExpressionAction(obj, paramsObj = {}) {
  if (_.isObject(obj)) {
    // SELECT_CONFIRM 的 actions 如果存在 SELECT_CONFIRM_ACTION_ID 不能提前计算表达式
    if (obj.SELECT_CONFIRM_ACTION_ID) {
      return obj;
    }

    _.forEach(obj, (value, idx) => {
      const black = ['response', 'navigate_callback', 'detail_dialog_json'];
      // action_callback需等数据返回后处理，故此处跳过
      // 此处对action_callback结尾的action都不做表达式处理，主要用于外层有阻断性的调用时，可以在阻断性的action执行完以后再执行下一步
      // 举例：有一个按钮，调用了一个自定义action(此action需要发起复杂的请求)，且有action_callback,此时可在外部将action_callback改名为a_action_callback, 这样，action_callback不会被立即执行，且其中的表达式也不会被立即计算，当复杂的自定义action执行完后，由外部调m_actionHandle方法主动触发a_action_callback,从而实现串行等待执行。
      if (!_.endsWith(idx, 'action_callback') && !black.includes(idx)) {
        const execResult = execExpressionAction(value, paramsObj);
        obj[idx] = execResult;
      }
    });
    return obj;
  }
  const result = execExpression(obj, paramsObj);
  return result;
}

// 系统 回退action
function goBack(delta) {
  uni.navigateBack({ delta });
}

// 获取对应的白板页
function getLayoutTmplPath(actionName = '') {
  if (actionName.indexOf('LIST') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.list;
  }
  if (actionName.indexOf('DETAIL') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.detail;
  }
  if (actionName.indexOf('CREATE') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.create;
  }
  if (actionName.indexOf('EDIT') > -1) {
    return NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH.edit;
  }
}
function getLayoutType(actionName = '') {
  return actionName.replace('TO_', '').toLowerCase();
}
export default {
  mixins: [MemoryStoreMixin, EmitAction],
  computed: {
    ...mapGettersWrap(['cosClass']),
  },
  data() {
    return {
      selectConfirmInitAction: null,
      cacheVariateKeys: [],
    };
  },
  destroyed() {
    this.cacheVariateKeys.forEach((key) => {
      this.$store.commit('action/setCacheVariate', { key, value: undefined });
    });
  },
  // props: {
  //   currentNamespaced: {
  //     type: String,
  //     default: '',
  //   },
  // },
  methods: {
    m_loading() {
      uni.showLoading({ mask: true });
      loadingNowSeq = loadingSeq;
      loadingSeq += 1;
      return loadingNowSeq;
    },
    // 跳转标准布局页
    goToTemplateLayout(action) {
      const { action_params: params, action: actionName } = action || {};
      const { object, id, force, record_type: recordType = '' } = params || {};
      let tplPath = getLayoutTmplPath(actionName);
      const layoutType = getLayoutType(actionName);
      let pathParams = `?path=${object}_${layoutType}&object=${object}&record_type=${recordType}`;
      if (!_.isNil(id)) {
        // 列表和新建不往路径中注入id
        if (!includes(['create', 'list'], layoutType)) {
          pathParams = `${pathParams}&id=${id.toString()}`;
        }
      } else if (_.has(params, 'sub_form_data')) {
        let { page_id: pageUniqId } = params || {};
        const { sub_form_data: subFormData } = params || {};
        const { form_value: formValue, form_data: formData } = params || {};
        pageUniqId = setPageData({
          pageUniqId,
          data: { subFormData, formValue, formData },
        });
        // 跳转后的页面通过pageKey获取需要的数据
        pathParams = `${pathParams}&pageUniqId=${pageUniqId}`;
      }
      tplPath = `${tplPath}${pathParams}`;
      this.g_tourl({
        ...getPathOpts(tplPath),
        force,
      });
    },
    async m_doActionHandle(action = {}, expParamsObj) {
      if (action?.__processed__) {
        return;
      }
      const shouldUpdateContext = action?.cmd || isCommonGqlAction(action);
      const actionCallback = async (context, callbackAction = null) => {
        // 若有action_callback，进入后续action
        const actionCallbackContent = callbackAction || action?.action_callback;
        if (actionCallbackContent) {
          const newExpParamsObj = this.getActionContext({
            context,
            pExpParams: expParamsObj,
          });
          let actionCallback = execExpressionAction(actionCallbackContent, newExpParamsObj);
          actionCallback = (await this.m_customAction(actionCallback)) || actionCallback || {};
          await this.m_doActionHandle(actionCallback, newExpParamsObj);
          // 透过action_callback发起的，需将事件往外抛出
          this.$emit('action-handle', actionCallback);
        }
      };
      this.setSCMAction(action, expParamsObj || this.memStoreGet(this.expParamsObj) || {});

      // 若是带了判断规则，则做判断，只有通过了判断，才执行当前action;
      if (action?.continue !== undefined) {
        const cont = execExpressionAction(
          action.continue,
          expParamsObj || this.memStoreGet(this.expParamsObj) || {},
        );
        if (cont === true) {
          action.__processed__ = true;
          // 只有cmd和confirm会往后边传入一层对象，故留一个空对象
          await actionCallback(shouldUpdateContext ? {} : undefined);
          return;
        }
      }
      if (action?.rule !== undefined) {
        const ruleRes = execExpressionAction(
          action.rule,
          expParamsObj || this.memStoreGet(this.expParamsObj) || {},
        );
        if (ruleRes !== true) {
          action.__processed__ = true;
          if (isString(ruleRes)) {
            throw new Error(ruleRes.toString());
          } else {
            return;
          }
        }
      }
      let context = {};
      if (!isExp(action?.url) && action?.url) {
        action.__processed__ = true;
        const { action_params: params } = action || {};
        if (_.has(params, 'sub_form_data')) {
          const { sub_form_data: subFormData } = params || {};
          let { page_id: pageUniqId } = params || {};
          const { form_value: formValue, form_data: formData } = params || {};
          pageUniqId = setPageData({
            pageUniqId,
            data: { subFormData, formValue, formData },
          });
          action.url = replaceParams({ pageUniqId }, action.url);
        }

        // 同时兼容列表格式与对象格式
        if (params?.navigate_callback && params.navigate_callback.length && this.addEmitListener) {
          params.navigate_callback.forEach((callback) => {
            const id = this.addEmitListener(callback.navigate_callback);
            action.url = replaceParams(
              {
                [`emit_after${callback.callback_hook}`]: id,
              },
              action.url,
            );
          });
        } else if (params?.navigate_callback && params?.callback_hook && this.addEmitListener) {
          const id = this.addEmitListener(params.navigate_callback);
          action.url = replaceParams(
            {
              [`emit_after${params.callback_hook}`]: id,
            },
            action.url,
          );
        }

        if (!this.isGoingPage) {
          // 小程序才加这个拦截，只有跳转成功后才能下一个跳转
          
          this.g_tourl({
            ...getPathOpts(action.url),
            appid: action.appid,
            force: params?.force,
            opts: {
              complete: () => {
                // 跳转做了两层的防抖，延迟恢复标识
                this.timer && clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                  this.isGoingPage = false;
                }, 100);
              },
            },
            open_type: params?.open_type,
          });
        }
      } else if (isNavigationAction(action)) {
        action.__processed__ = true;
        this.goToTemplateLayout(action);
      } else if (isNoticeAction(action)) {
        action.__processed__ = true;
        await noticeAction(action);
      } else if (isMsgAction(action)) {
        action.__processed__ = true;
        await msgAction(action);
      } else if (action?.cmd) {
        // 调用cmd接口逻辑
        action.__processed__ = true;
        try {
          const seq = this.m_loading();
          let resp = await cmdActionRequest(action);
          hideLoading(seq);
          if (action?.response) {
            const newExpParamsObj = this.getActionContext({
              context: resp || {},
              pExpParams: expParamsObj,
            });
            resp = execExpressionAction(action.response, newExpParamsObj);
          }
          _.set(action, 'action_params._response', resp);
          context = resp || {};
          // this.m_doActionCallback({ action, context: resp });
        } catch (e) {
          hideLoading();
          throw e;
        }
      } else if (isCommonGqlAction(action)) {
        // 调用graphql接口
        action.__processed__ = true;
        try {
          const seq = this.m_loading();
          const resp = await graphqlActionRequest(action);
          hideLoading(seq);
          _.set(action, 'action_params._response', resp);
          context = resp || {};
          // this.m_doActionCallback({ action, context: resp });
        } catch (e) {
          hideLoading();
          throw e;
        }
      } else if (isConfirmAction(action)) {
        action.__processed__ = true;
        const confirmRule = action?.action_params?.rule;
        if (confirmRule || confirmRule === undefined) {
          const confirm = action.m_confirmAction
            ? await this.m_confirmAction(action)
            : await confirmAction(action);
          action.__confirm__ = confirm;
          if (!confirm) {
            if (action.cancel_action_callback) {
              await actionCallback(context, action.cancel_action_callback);
            }
            this.$emit('action-handle', action);
            return false;
          }
        }
      } else if (isSelectConfirmAction(action)) {
        action.__processed__ = true;
        const act = this.findSelectConfirmAction(
          this.selectConfirmInitAction,
          'action',
          'SELECT_CONFIRM',
          expParamsObj || this.memStoreGet(this.expParamsObj),
          action.SELECT_CONFIRM_ID,
        );
        const emitCallback = () => this;
        const openFun = selectConfirmDialog.open[selectConfirmDialog.open.length - 1];
        this.$nextTick(() => {
          openFun
            && openFun(
              { ...action, cosClass: this.cosClass },
              expParamsObj || this.memStoreGet(this.expParamsObj),
              _.cloneDeep(act),
              emitCallback,
            );
        });
        return;
      } else if (action?.action === 'BACK') {
        action.__processed__ = true;
        const delta = action?.action_params?.delta;
        goBack(delta || 1);
      } else if (action?.action === 'CLIPBOARD') {
        action.__processed__ = true;
        const cliboadrRes = await clipboardAction({ action });
        action.__cliboard__ = cliboadrRes;
      } else if (action?.action === 'SORT') {
        const { action_params: params } = action || {};
        const {
          sort_list: sortList,
          sort_values: sortValues,
          force,
          page_id: pageId,
          config,
        } = params || {};
        const sortPath = '/pages/layout/mutation/sort';
        const pageUniqId = setPageData({
          data: { sortList, sortValues, config },
          pageUniqId: pageId,
        });
        // 跳转后的页面通过pageKey获取需要的数据
        const pathParams = `?pageUniqId=${pageUniqId}`;
        const pageFullPath = `${sortPath}${pathParams}`;
        action.__processed__ = true;
        this.g_tourl({
          ...getPathOpts(pageFullPath),
          force,
        });
      } else if (action?.action === 'SHARE_DATA') {
        action.__processed__ = true;
        const openFun = globalSharePopup.open[globalSharePopup.open.length - 1];
        openFun && openFun(action);
      } else if (action?.action === 'DATA_HISTORY') {
        const scopeExpParams = expParamsObj || this.memStoreGet(this.expParamsObj);
        const dataHistoryUrl = replaceParams(
          {
            params: encodeURIComponent(
              JSON.stringify({
                object: scopeExpParams?.json?.object,
                id: scopeExpParams?.t?.id,
                ...action?.action_params,
              }),
            ),
          },
          '/pages/layout/data-history/index',
        );
        this.g_tourl({
          wxaurl: dataHistoryUrl,
        });
        action.__processed__ = true;
      } else if (isVariateAction(action)) {
        const { action_params: actionParams, action: actionName } = action;
        const { key } = actionParams;
        if (actionName === 'SET_VARIATE') {
          this.cacheVariateKeys.push(key);
        }
        context = variateAction(action, this.$store.state.action.cacheVariate, this.$store.commit);
        action.__processed__ = true;
        console.log('------context------', context);
      } else if (action?.action === 'DETAIL_DIALOG') {
        action.__processed__ = true;
        globalDetailDialog.open(action);
      }
      
      else if (typeof action?.CUSTOM_ACTION === 'function') {
        /**
         * TODO:该逻辑分支必须处在最后一个
         */
        context = (await action.CUSTOM_ACTION(action)) || {};
      }
      await actionCallback(context);
    },
    // selectConfirmAction 分步提交数据将json暂存，防止 execExpressionAction 提前计算表达式
    setSCMAction(action, expParamsObj) {
      // SELECT_CONFIRM 生成唯一ID
      let idCounter = 1;
      function assignUniqueIds(obj) {
        if (obj instanceof Array) {
          obj.forEach((item) => assignUniqueIds(item));
        } else if (typeof obj === 'object' && obj !== null) {
          Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (typeof value === 'object') {
              assignUniqueIds(value);
            } else if (key === 'action') {
              const expressionRes = isExp(value) ? execExpression(value, expParamsObj) : value;
              if (expressionRes !== 'SELECT_CONFIRM') {
                return;
              }
              if (!obj.SELECT_CONFIRM_ID) {
                obj.SELECT_CONFIRM_ID = idCounter;
                if (obj.actions?.length) {
                  obj.actions.forEach((i, index) => {
                    i.SELECT_CONFIRM_ACTION_ID = `${idCounter}-${index}`;
                  });
                }
                idCounter += 1;
                // show属性不提前计算
                if (obj.action_params?.formItem?.length) {
                  obj.action_params?.formItem.forEach((i) => {
                    i.show = {
                      show: Object.prototype.hasOwnProperty.call(i, 'show') ? i.show : true,
                      SELECT_CONFIRM_ACTION_ID: true,
                    };
                  });
                }
              }
            }
          });
        }
      }
      if (~JSON.stringify(action).indexOf('SELECT_CONFIRM')) {
        assignUniqueIds(action);
        this.selectConfirmInitAction = _.cloneDeep(action);
      }
    },
    findSelectConfirmAction(obj, targetKey, targetValue, expParamsObj, SELECT_CONFIRM_ID) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (typeof value === 'object' && value !== null) {
            const result = this.findSelectConfirmAction(
              value,
              targetKey,
              targetValue,
              expParamsObj,
              SELECT_CONFIRM_ID,
            );
            if (result) {
              return result;
            }
          } else if (key === targetKey && obj.SELECT_CONFIRM_ID === SELECT_CONFIRM_ID) {
            // action 为表达式场景
            const expressionRes = isExp(value) && execExpression(value, expParamsObj);
            if (value === targetValue || expressionRes === targetValue) {
              return obj;
            }
          }
        }
      }
      return null;
    },
    // 获取action对象的上下文对象
    getActionContext({ context = {}, pExpParams }) {
      const expParamsObj = pExpParams ? pExpParams : this.memStoreGet(this.expParamsObj);
      return expParamsObj
        ? {
            ...expParamsObj,
            t: {
              ...context,
              p: expParamsObj?.t, // 将外层数据置为其父数据
            },
          }
        : {
            t: { ...context },
          };
    },
    // 回调action
    // async m_doActionCallback({ action, context = {} }) {
    //   const paramsObj = this.getActionContext({ context });
    //   const actionCallback = execExpressionAction(action?.action_callback, paramsObj) || {};
    //   if (_.includes(CONFIRM_ACTION, actionCallback?.action)) {
    //     const confirm = action.m_confirmAction
    //       ? await this.m_confirmAction(actionCallback)
    //       : await confirmAction(actionCallback);
    //     if (confirm) {
    //       const inActionCallback
    //         = execExpressionAction(actionCallback?.action_callback, paramsObj) || {};
    //       this.m_doActionHandle(inActionCallback);
    //     }
    //   } else {
    //     this.m_doActionHandle(actionCallback);
    //   }
    //   // 抛出callback逻辑
    //   this.$emit('action-handle', actionCallback);
    // },
    async m_doPreActionHandle(action = {}) {
      try {
        let resp = {};
        if (action?.pre_action?.cmd) {
          action.pre_action.__processed__ = true;
          const seq = this.m_loading();
          resp = await cmdActionRequest(action?.pre_action);
          hideLoading(seq);
          if (!action.pre_action?.action_params) {
            action.pre_action.action_params = {};
          }
          action.pre_action.action_params._response = resp;
        }
        const callback = action?.pre_action?.action_callback;
        if (callback) {
          action.pre_action.action_callback = true;
          const paramsObj = this.getActionContext({ context: resp });
          const result = execExpressionAction(callback, paramsObj);
          // 若传入的判断方法不是对象，则直接判断其表达式是否为true，表示是否通过前置action
          if (!_.isPlainObject(callback)) {
            if (result !== true && result !== undefined) {
              throw new Error(result.toString());
            }
          } else {
            // 若传入的是对象，则判断对象的rule是否为true，表示是否通过前置action;
            if (result?.rule !== true && result?.rule !== undefined) {
              if (isString(result.rule)) {
                throw new Error(result.rule.toString());
              }
              return false;
            }
            if (_.includes(CONFIRM_ACTION, result?.action)) {
              const confirmRule = result?.action_params?.rule;
              if (confirmRule || confirmRule === undefined) {
                // 通过前置action，若带了alert或confirm等提示操作，则先执行
                const confirm = action.m_confirmAction
                  ? await this.m_confirmAction(result)
                  : await confirmAction(result);
                action.__confirm__ = confirm;
                if (!confirm) {
                  this.$emit('action-handle', action);
                  return false;
                }
              }
            }
          }
        }
      } catch (e) {
        hideLoading();
        throw e;
      }
      return true;
    },
    // 可由组件集成重写的方法
    async m_customAction(data) {
      return data;
    },
    async m_confirmAction() {},
    async m_actionHandle(action = {}, expParamsObj) {
      const { action_params: actionParams = {} } = action;
      let deepAction = _.cloneDeep(action);
      const newExpParamsObj = expParamsObj || this.memStoreGet(this.expParamsObj) || {};
      this.setSCMAction(deepAction, newExpParamsObj);
      deepAction = execExpressionAction(deepAction, newExpParamsObj) || deepAction;
      deepAction = (await this.m_customAction(deepAction)) || deepAction || {};
      if (!deepAction.__processed__) {
        if (!isExp(deepAction.open_type) && !deepAction?.open_type) {
          try {
            if (!(await this.m_doPreActionHandle(deepAction))) {
              return;
            }
            await this.m_doActionHandle(deepAction, newExpParamsObj);
          } catch (e) {
            deepAction.__processed__ = true;
            setTimeout(() => {
              if (!actionParams?.error_modal) {
                this.g_showToast(e.message || '请求action失败');
              } else {
                uni.showModal({
                  content: e.message,
                  showCancel: false,
                  title: actionParams.error_modal?.title || '',
                  confirmText: '知道了',
                });
              }
            });
          }
        }
      }
      // 直接往外抛出事件
      this.$emit('action-handle', deepAction);
    },
  },
};
