//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  data() {
    return {};
  },
  computed: {
    canShowActions() {
      return !this.disabled && (this.canCopy || this.canRetry || this.canUseText);
    },
    canCopy() {
      // 能复制
      return (this.config?.can_copy && this.message.can_copy) || false;
    },
    canRetry() {
      // 能重试
      return (this.config?.can_retry && this.message.can_retry) || false;
    },
    canUseText() {
      // 可以使用文本
      return this.message.can_use_text || false;
    },
    botConfig() {
      return this.message?.request?.bot_config;
    },
  },
  methods: {
    handleAiAction(actionName) {
      const action = { actionName, message: this.message };
      const { reply, source } = this.message?.response || {};
      let msgText = reply?.map((item) => item?.text || '')?.join('') || '';

      if (source?.length) {
        msgText += '\n\n【参考文档】';
        for (let i = 0; i < source?.length; i++) {
          msgText += '\n\n';
          msgText += `[${i + 1}]${source[i].title}` || '-';
          for (const messageObj of source[i].content) {
            msgText += `\n·${messageObj?.text || ''}`;
          }
        }
      }
      action.msgText = msgText;
      this.$emit('action', action);
    },
  },
};
