//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import classNames from '../utils/classnames';
import Action from '../action';
import { cloneDeep, isObject, isEmpty, isPlainObject, get } from 'lodash';
import { execExpressionDeep } from '../../layout/expression';
import MemoryStoreMixin from '../memory-store/memory-store-mixin';
import { throttle, getSystemInfo } from '../../util';
import TrackingCompMixin from 'nges-common/src/tracking/mixins/comp-mixin';

export default {
  options: { styleIsolation: 'shared' },
  mixins: [Action, MemoryStoreMixin, TrackingCompMixin],
  props: {
    action: {
      type: [Object, String, null],
      default() {
        return {};
      },
    },
    /**
     * 主题
     * default(默认是 primary 色边框的效果)
     * primary | success | warning | danger
     * text（默认是primary 颜色）
     */
    type: {
      type: [String, null],
      default: 'default',
    },
    // 样式、形式 default(深色背景) | outline(描边)
    variant: {
      type: String,
      default: 'default',
    },
    /**
     * gray | primary | success | warning | danger
     * 如果想要在 type 的基础上控制颜色，可以用 theme 叠加
     * 比如：想要一个灰色边框的次要按钮（默认是 primary 色边框）
     * 比如：想要一个危险颜色的文字按钮按钮（默认是）
     */
    theme: {
      type: String,
      default: undefined,
    },
    // 形状 default | round(大圆角)
    shape: {
      type: String,
      default: 'default',
    },
    label: {
      type: [String, null],
      default: '',
    },
    // large | medium | small | xs
    size: {
      type: String,
      default: 'medium',
    },
    disabled: Boolean,
    block: Boolean,
    customStyle: [Object, String],
    expParamsObj: {
      type: [Object, String, Array],
      default() {
        return {};
      },
    },
    // 点击是否需要回调，emit的click事件是异步的话需要回调
    needCallback: {
      type: Boolean,
      default: false,
    },
    useCoverView: {
      type: Boolean,
      default: false,
    },
    currentNamespaced: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    needThrottle: {
      type: Boolean,
      default: true,
    },
    actionKey: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, null],
      default: '',
    },
    showType: {
      type: [String, null],
      default: '',
    },
    iconImage: {
      type: [String, null],
      default: '',
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      isIOS: false,
      customAction: null,
      // json作用域方法集合，prop不能传递方法
      customFunc: null,
    };
  },
  computed: {
    className() {
      const name = 'nges-button';
      return classNames([
        name,
        // @ts-ignore
        this.getActionProp('class_name'),
        this.customClass,
        `${name}--variant-${this.variant}`,
        `${name}--type-${this.type}`,
        `${name}--shape-${this.shape}`,
        `${name}--size-${this.size}`,
        {
          [`${name}--theme-${this.theme}`]: this.theme,
          [`${name}--block`]: this.block,
          [`${name}--disabled`]: this.calcDisabled,
        },
        {
          [`${name}--loading`]: this.loading,
        },
        this.isIOS ? `${name}--ios` : '',
      ]);
    },
    calcDisabled() {
      return this.disabled || this.execExpression(this.getActionProp('disabled')) || false;
    },
  },
  watch: {
    useCoverView() {
      this.resetCoverViewStyle();
    },
    actionKey() {
      // 将外置的方法通过回调的方式设置进来
      this.$emit('button-mounted', { cb: this.setCustomFunc });
    },
  },
  created() {
    const { isIOS } = getSystemInfo();
    this.isIOS = isIOS;
  },
  destroyed() {
    this.customFunc = null;
    this.customAction = null;
  },
  mounted() {
    // 将外置的方法通过回调的方式设置进来
    this.$emit('button-mounted', { cb: this.setCustomFunc });
    this.resetCoverViewStyle();
    this.onClick = this.needThrottle
      ? throttle(this.handleClk, 2000, { trailing: false })
      : this.handleClk;
  },
  methods: {
    setCustomFunc({ customFunc, customAction }) {
      if (customFunc) {
        this.customFunc = customFunc;
      }
      if (customAction) {
        this.customAction = customAction;
      }
    },
    execExpression(field) {
      let params = field;
      if (isObject(field)) {
        params = cloneDeep(field);
      }
      return execExpressionDeep(params, this.memStoreGet(this.expParamsObj));
    },
    resetCoverViewStyle() {
      if (this.useCoverView && !this.top) {
        let query = this.createSelectorQuery();
        query
          .select('.btn')
          .boundingClientRect((rect) => {
            this.height = `${rect.height}px`;
            this.width = `${rect.width}px`;
            this.left = `${rect.left}px`;
            this.top = `${rect.top}px`;
            query = null;
          })
          .exec();
      }
    },
    handleClk() {
      if (this.loading) return;
      if (!this.calcDisabled) {
        const expParamsObj = this.memStoreGet(this.expParamsObj);
        if (!isEmpty(this.customFunc) && isPlainObject(this.customFunc)) {
          expParamsObj.m = this.customFunc;
        }
        this.$emit('click', this.getAction(), () =>
          this.needCallback ? this.m_actionHandle(this.getAction(), expParamsObj) : null,
        );
        !this.needCallback && this.m_actionHandle(this.getAction(), expParamsObj);

        // 按钮点击事件上报
        this.__compTracking(
          {
            event_label: (expParamsObj?.json || {})?.title,
            event_name: `${this.execExpression(this.getActionProp('label')) || ''}点击`,
            event_type: 'click',
            event_id: 'click_Event',
          },
          this.execExpression(this.getActionProp('tracking')),
        );
      }
    },
    getActionProp(key) {
      let action = this.memStoreGet(this.action)
      action = this.customAction || action;
      const res = get(action, key);
      return res;
    },
    getAction() {
      let action = this.memStoreGet(this.action)
      action = this.customAction || action;
      return action;
    }
  },
};
