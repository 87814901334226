//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MButton from 'nges-common/src/components/button/index.vue';
import MBottomAction from 'nges-common/src/components/bottom-action/index.vue';

export default {
  components: {
    MButton,
    MBottomAction,
  },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      negativeOptions: [],
      enableSuggestion: false,
      checkedOptions: [],
      suggestion: '',
    };
  },
  methods: {
    open() {
      this.suggestion = '';
      this.checkedOptions = [];
      this.getNegativeReason();
      this.$refs.popup.open();
    },
    async getNegativeReason() {
      try {
        const aiChatbotConfig = this.config;
        this.negativeOptions = (aiChatbotConfig.negative_options || []).map((group) => {
          return {
            ...group,
            options: (group.options || []).map((item) => ({ option: item, checked: false })),
          };
        });
        this.enableSuggestion = aiChatbotConfig.feedback_suggestion || false;
      } catch (e) {
        this.negativeOptions = [];
        this.enableSuggestion = false;
      }
    },
    toggleOption(option) {
      const idx = this.checkedOptions.findIndex((item) => item === option.option);
      this.$set(option, 'checked', idx === -1);
      if (idx === -1) {
        this.checkedOptions.push(option.option);
      } else {
        this.checkedOptions.splice(idx, 1);
      }
    },
    confirm() {
      const feedbackContent = [].concat(this.checkedOptions);
      if (this.suggestion) {
        feedbackContent.push(this.suggestion);
      }
      this.$emit(
        'feedback-confirm',
        {
          ...this.message,
          feedback: 'negative',
          feedbackContent,
        },
        () => {
          this.$refs.popup.close();
        },
      );
    },
    close() {
      this.reset();
      this.$refs.popup.close();
      this.$emit('feedback-close', {
        ...this.message,
      });
    },
    reset() {
      this.suggestion = '';
      this.checkedOptions = [];
      this.getNegativeReason();
    },
  },
};
