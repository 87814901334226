import request from '@/libs/request';
import {
  queryArticleStrategyList,
  queryArticleDetail,
  queryArticleDelete,
  queryUpdateArticle,
  queryArticleRecordData,
  queryArticleRecordOfficialAccountData,
  queryPushLineList,
  queryCancelMission,
  queryArticleRecordMission,
  queryArticleRecordVersion,
  queryUpdateArticleValid,
  queryArticleValidScope,
  insertArticleValidScope,
  updateArticleValidScope,
  deleteArticleValidScope,
  queryAddPushTaskContent,
  queryGetPushTaskContent,
  queryGetCurrentPushTaskContent,
  queryDeleteCurrentPushTaskContent,
  querySavePushTask,
  queryEditPushTaskContent,
  queryGetPushTaskAnalyzeResults,
  queryEditPushTaskAnalyzeResults,
  queryPushTaskList,
  queryGetArticleName,
  queryCourseListDetail,
} from '@/graphql/api/article';
import { queryTagCategoryList, queryTagLabelList } from '@/graphql/api/tag';
import {
  queryInsertMaterial,
  querylistMaterial,
  queryDelMaterial,
  queryEditMaterial,
  querylistMaterialOfficial,
  queryInsertVideo,
  queryMaterialValidScope,
  insertMaterialValidScope,
  updateMaterialValidScope,
  deleteMaterialValidScope,
  queryImageByIdList,
} from '@/graphql/api/material';
import { queryUserList } from '@/graphql/api/user';
import {
  queryBannerList,
  queryBannerBannerInsert,
  queryGetBannerByID,
  queryBannerUpdate,
  queryBannerOnLine,
  queryBannerDelete,
  queryBannerListByID,
} from '@/graphql/api/banner';
import {
  queryArticleListValidScope,
  updateArticleListValidScope,
  insertArticleListValidScope,
  deleteArticleListValidScope,
} from '@/graphql/api/articleList';

// 增加推送内容详情表
export const AddPushTaskContent = (data) => queryAddPushTaskContent(data);
// 编辑推送内容详情表
export const EditPushTaskContent = (data) => queryEditPushTaskContent(data);
// 获取推送内容详情表
export const GetPushTaskContent = (data) => queryGetPushTaskContent(data);
// 获取新增的页面添加的推送内容详情表
export const GetCurrentPushTaskContent = (data) => queryGetCurrentPushTaskContent(data);
// 删除推送内容详情表的条目
export const DeleteCurrentPushTaskContent = (data) => queryDeleteCurrentPushTaskContent(data);
// 保存智能推送任务
export const SavePushTask = (data) => querySavePushTask(data);
// 分析文章智能推送任务
export const StartAnalyzeSmartPushTask = (data) =>
  request('McmAdminServer/ArticleService/StartAnalyzeSmartPushTask', data);
// 创建文章智能推送任务
export const CreateSmartPushTask = (data) =>
  request('McmAdminServer/ArticleService/CreateSmartPushTask', data);
// 取消文章智能推送任务
export const StopAnalyzeSmartPushTask = (data) =>
  request('McmAdminServer/ArticleService/StopAnalyzeSmartPushTask', data);
// 获取智能分析结果
export const GetPushTaskAnalyzeResults = (data) => queryGetPushTaskAnalyzeResults(data);
// 编辑智能分析结果
export const EditPushTaskAnalyzeResults = (data) => queryEditPushTaskAnalyzeResults(data);
// 发送任务列表
export const PushTaskList = (data) => queryPushTaskList(data);
// 智能生成发送任务
export const SendSmartPushTask = (data) =>
  request('McmAdminServer/ArticleService/SendSmartPushTask', data);
// 获得文章标题
export const GetArticleName = (data) => queryGetArticleName(data);

export const labelComplexManagerQueryApi = (data) =>
  request('/api/labelComplex/manager/query', data);
// 上传视频
export const EyaoSaaSInsertVideoApi = (data) => queryInsertVideo(data);

export const EyaoSaaSSynCreateMaterialApi = (data, otherData) =>
  queryInsertMaterial(data, otherData);
export const EyaoSaaSSelectMaterialApi = (data) => querylistMaterial(data);
export const EyaoSaaSSelectMaterialOfficialApi = (data) => querylistMaterialOfficial(data);
export const EyaoSaaSDeleteMaterialApi = (data) => queryDelMaterial(data);

// adobe文件转存
export const EyaoSaaSDeleteMaterialApiFromAdobe = (data) =>
  request('FaasGatewayServer/func/AEMSelectorMaterialSync', data);

export const EyaoSaaSUpdateMaterialPubRefApi = (data) => queryEditMaterial(data);
export const UpdateArticleValid = (data) => queryUpdateArticleValid(data);
// 根据素材id查询详细信息
export const GetMaterialByIDs = (data) => request('GetMaterialByIDs', data);
export const bannerInfoManagerModifyBannerInfoApi = (data) =>
  request('/api/bannerInfo/manager/modifyBannerInfo', data);

function ProcessValidScopeStartEndTime(effectiveStatus, startTime, endTime) {
  startTime = startTime ? Number(startTime) : 0;
  endTime = endTime ? Number(endTime) : 0;
  if (Number(effectiveStatus) === 2) {
    // 失效状态不存数据
    startTime = 0;
    endTime = 0;
  }

  if (startTime) {
    const date = new Date(startTime * 1000);
    date.setHours(0, 0, 0, 0);
    startTime = Math.floor(date.getTime() / 1000);
  }

  if (endTime) {
    const date = new Date(endTime * 1000);
    date.setHours(23, 59, 59, 59);
    endTime = Math.floor(date.getTime() / 1000);
  }

  return [startTime, endTime];
}

// 新增 更新 删除Scope (有begin_time & end_time 更新or新增,  没有删除)
export const OperateArticleValidScope = async (data) => {
  if (!data?.id) {
    return Promise.reject({ message: 'id为空' });
  }

  const hasScope = (await GetArticleValidScope(data))?.mcm_article_valid_scope;
  const timeObj = ProcessValidScopeStartEndTime(
    data.effective_status,
    data.begin_time,
    data.end_time,
  );
  data.begin_time = timeObj[0];
  data.end_time = timeObj[1];

  if (data.begin_time && data.end_time) {
    // upsert 暂不支持主表
    if (hasScope) {
      return UpdateArticleValidScope(data).catch(() => null);
    }
    return SetArticleValidScope(data);
  }

  if (hasScope) {
    return DeleteArticleValidScope(data);
  }
  return Promise.resolve({});
};

// 新增 更新 删除Scope (start_time & end_time 更新or新增,  没有删除)
export const OperateMaterialValidScope = async (data) => {
  if (!data?.id) {
    return Promise.reject({ message: 'id为空' });
  }

  const hasScope = (await GetMaterialValidScope(data))?.mcm_material_valid_scope;
  const timeObj = ProcessValidScopeStartEndTime(
    data.effective_status,
    data.start_time,
    data.end_time,
  );
  data.start_time = timeObj[0];
  data.end_time = timeObj[1];

  if (data.start_time && data.end_time) {
    // upsert 暂不支持主表
    if (hasScope) {
      return UpdateMaterialValidScope(data);
    }
    return SetMaterialValidScope(data);
  }
  if (hasScope) {
    return DeleteMaterialValidScope(data);
  }
  return Promise.resolve({});
};
export const OperateArticleListValidScope = async (data) => {
  if (!data?.id) {
    return Promise.reject({ message: 'id为空' });
  }
  const hasScope = (await GetArticleListValidScope(data))?.mcm_article_list_valid_scope;
  const timeObj = ProcessValidScopeStartEndTime(
    data.effective_status,
    data.start_time,
    data.end_time,
  );
  data.start_time = timeObj[0];
  data.end_time = timeObj[1];

  if (data.start_time && data.end_time) {
    // upsert 暂不支持主表
    if (hasScope) {
      return UpdateArticleListlValidScope(data);
    }
    return SetArticleListValidScope(data);
  }
  if (hasScope) {
    return DeleteArticleListValidScope(data);
  }
  return Promise.resolve({});
};
// 根据id查询素材有效期
export const GetMaterialValidScope = (data) => queryMaterialValidScope(data);
// 根据id设置素材有效期
export const SetMaterialValidScope = (data) => insertMaterialValidScope(data);
// 根据id更新素材有效期
export const UpdateMaterialValidScope = (data) => updateMaterialValidScope(data);
// 根据id删除素材有效期
export const DeleteMaterialValidScope = (data) => deleteMaterialValidScope(data);

// 根据图片id查询图片信息
export const QueryImageByIdList = (data) => queryImageByIdList(data);

// 上下架文章
export const setArticleStatusApi = (data) => request('UpdateArticleStatus', data);
// 上架企业应用文章
export const EyaoSaaSTakeUpArticlesApi = (data) => request('EyaoSaaSTakeUpArticles', data);
// 下架企业应用文章
export const EyaoSaaSTakeDownArticlesApi = (data) => request('EyaoSaaSTakeDownArticles', data);
// 发布到微信公众号号(按策略发送文章到公众号)
export const SendOfficialAccountsByStrategy = (data) =>
  request('McmAdminServer/ArticleService/SendOfficialAccounts', data);
// 发布前校验
export const CheckArticlePublish = (data) => request('CheckArticlePublish', data);
// 发布到微信公众号号
export const publishArticleWeChatPageApi = (data) => request('PublishArticleToWechat', data);
// 发布到微信小程序
export const publishArticleToMiniApi = (data) => request('PublishArticleToMini', data);
// 文章发布
export const listPublishRecodeApi = (data) => queryArticleRecordOfficialAccountData(data);
// 获取文章列表
export const getArticleListApi = (data) => request('getArticleListApi', data);
// 获取企业应用文章列表
export const EyaoSaaSQueryArticlesApi = (data) => request('EyaoSaaSQueryArticles', data);
// 根据id查询文章效期
export const GetArticleValidScope = (data) => queryArticleValidScope(data);
// 根据id设置文章效期
export const SetArticleValidScope = (data) => insertArticleValidScope(data);
// 根据id更新文章效期
export const UpdateArticleValidScope = (data) => updateArticleValidScope(data);
// 根据id删除文章效期
export const DeleteArticleValidScope = (data) => deleteArticleValidScope(data);
// 根据id查询文章
export const GetArticleByIDApi = (data, otherKeys) => queryArticleDetail(data, otherKeys);
// 根据id删除文章(进行AZ插入问卷失败后使用, 且是新增)
export const DeleteArticleByIDApi = (data) => queryArticleDelete(data);
// 根据id查询课单
export const GetCourseListByIDApi = (data) => queryCourseListDetail(data);
// 更新文章(公众号)
export const UpdArticleResApi = (data) => queryUpdateArticle(data);
// 新建文章(企业应用)
export const EyaoSaaSCreateArticleApi = (data) => request('EyaoSaaSCreateArticle', data);
// 更新文章(企业应用)
export const EyaoSaaSUpdateArticleApi = (data) => request('EyaoSaaSUpdateArticle', data);
// XXX
export const userCountByAppIdAndStrategyIdApi = (data) => request('/', data);
// 单篇文章数据明细列表
export const getArticleStatByArtIDApi = (data) => queryArticleRecordData(data);
// 公众号记录的导出
export const ExportArticleRecords = (data) => request('ExportArticleRecords', data);
// 发布预览
export const preViewWechatMessageApi = (data) =>
  request('McmAdminServer/ArticleService/PreviewArticle', data);
// 发布前校验
export const preCheckArticleWechatMessageApi = (data) =>
  request('McmAdminServer/ArticleService/PreCheckArticle', data);
// 文章发布快照详情
export const getPublishArticleWechatDetailApi = (data) => queryArticleRecordVersion(data);
// 发布到企业微信
export const updateEyaoSaaSWxArticlesPublicshSendApi = (data) =>
  request('EyaoSaaSWxArticlesPublicshSend', data);
// 查询发布列表
export const GetArticlePushList = (data) => queryPushLineList(data);
// 撤回发布
export const ArticlePushReallocate = (data) =>
  request('McmAdminServer/ArticleService/WithdrawArticle', data);
// 取消发布
export const ArticleCancelPush = (data) => queryCancelMission(data);
// 读取被删除的媒体文件数据
export const getDeletedMediaListApi = (data) => request('GetDeletedMediaList', data);
/** 运营位设置 */
/* 查询横幅列表，即将被废弃 */
export const getBannerListApi = (data) => request('GetBannerList', data);
// 新的查询banner接口
export const GetBanners = (data) => queryBannerList(data);
// 根据id查询banner数组接口
export const GetBannerListByIDApi = (data) => queryBannerListByID(data);
/* 上下线横幅 */
export const offlineBannerApi = (data) => queryBannerOnLine(data);
// export const offlineBannerApi = (data) => request('OfflineBanner', data);
/* 删除横幅 */
export const deleteBannerApi = (data) => queryBannerDelete(data);
/* 读取横幅数量上限 */
export const getBannerLimitApi = (data) => request('GetBannerLimit', data);
/* 新增banner */
// export const addBannerApi = (data) => request('AddBanner', data);
export const addBannerApi = (data) => queryBannerBannerInsert(data);
// 编辑banner
export const updateBannerApi = (data) => queryBannerUpdate(data);
/* 读取横幅信息 */
export const getBannerByIDApi = (data) => queryGetBannerByID(data);
// export const getBannerByIDApi = (data) => request('GetBannerByID', data);
/* banner排序 */
export const updateSortApi = (data) => request('RemoveSort', data);
/* 获取药企基本信息 */
export const getCompanyInfoApi = (data) => request('GetCompanyInfo', data);

/* 投票管理 */
// 投票列表
export const GetVoteListApi = (data) => request('GetVoteList', data);
/* 删除投票 */
export const DelVoteApi = (data) => request('DelVote', data);
/* 读取投票详情 */
export const GetVoteByIDApi = (data) => request('GetVoteByID', data);
/* 新增投票 */
export const AddVoteApi = (data) => request('AddVote', data);
/* 编辑投票 */
export const modifyVotesInfoApi = (data) => request('/api/vote/manager/modifyVotesInfo', data);
/* 更新投票截止时间 */
export const UpdVoteEndtimeApi = (data) => request('UpdVoteEndtime', data);
/* 导出投票信息 */
export const ExportVoteInfoApi = (data) => request('ExportVoteInfo', data);
/* 读取投票状态枚举 */
export const GetVoteStatusCfgApi = (data) => request('GetVoteStatusCfg', data);

/**
 * 文档管理
 */
/* 查询空间使用量 */
export const GetSpaceInfo = (data) => request('ApiServer/Cos/GetSpaceInfo', data);
/* 查询文档详情 */
export const GetDocInfo = (data) => request('GetDocInfo', data);
/* 查询文档列表 */
export const GetDocList = (data) => request('GetDocList', data);
/* 上传文档（包含断点续传） */
export const UploadDoc = (data) => request('UploadDoc', data);
/* 修改文档名称 */
export const RenameDoc = (data) => request('RenameDoc', data);
/* 删除文档 */
export const DelDoc = (data) => request('DelDoc', data);
/* 下载文档 */
export const DownloadDoc = (data) => request('DownloadDoc', data);
/* 复制文档链接 */
export const CopyDocUrl = (data) => request('CopyDocUrl', data);
/* 查看文档使用日志 */
export const GetDocLog = (data) => request('GetDocLog', data);
/* 导出文档使用日志 */
export const GetLogExcel = (data) => request('GetLogExcel', data);
/* 查询文档格式列表 */
export const GetDocTypeList = (data) => request('GetDocTypeList', data);
/* 获取全量标签分类 */
export const GetAllLabelInfoList = (data) => queryTagCategoryList(data);
/* 获取分类下标签 */
export const GetTagInfoList = (data) => queryTagLabelList(data);
/* 获取文章发送时可选策略 */
export const GetArticleStrategyList = (data) => queryArticleStrategyList(data);
/* 获取用户列表判断是否合法用户 */
export const GetUserList = (data) => queryUserList(data);
/* 发布任务获取文章数据 */
export const GetPublicArticleData = (data) => queryArticleRecordMission(data);

export const GetOfficialArticle = (data) =>
  request('McmAdminServer/ArticleService/PullDraft', data);

export const GetRoleTreeInfo = (data) => request('FaasGatewayServer/func/GetRoleTreeInfo', data);

export const GetArticleListValidScope = (data) => queryArticleListValidScope(data);

export const UpdateArticleListlValidScope = (data) => updateArticleListValidScope(data);

export const SetArticleListValidScope = (data) => insertArticleListValidScope(data);

export const DeleteArticleListValidScope = (data) => deleteArticleListValidScope(data);
