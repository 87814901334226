/**
 * 流式请求
 */
import axios from 'axios';
import { requestConfigOptions } from 'nges-common/src/web/utils/request';
import config from 'nges-common/src/web/config';
import login from 'nges-common/src/web/utils/login';
import { traceidGenerator } from 'nges-common/src/web-mobile/util';
// const cloud = `${config.domain}${config.api}`;
const token = login.getLoginInfo('token');
const { product, platform, adtag = '' } = requestConfigOptions;

export const streamRequest = (cmd, payload) => {
  const params = {
    header: {
      version: '',
      flag: 0,
    },
    body: {
      client: {
        platform, // 客户端平台， 小程序=0  公众号=1  APP=2 QQ浏览器小程序=3
        env: '', // 标识环境
        isTourist: token && !/-yk$/.test(token) ? 48 : 49, // 游客标识 0: 登录用户; 1: 游客
        product,
        adtag,
      },
      cmd,
      payload,
      seq: 0,
      // token,
      token,
      traceid: traceidGenerator(),
    },
  };
  // console.log('哈哈哈哈', params);

  return axios.request({
    url: `${config.cloud}/cmd/${cmd}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'stream',
    data: JSON.stringify(params),
  });
};


