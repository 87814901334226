import { getLanguage, getLanguageFromCache } from './downloadLanguagePkg';

/**
 * 构造函数
 * env 当前环境
 */
function I18n(env) {
  const realEnv = env; // || window?.i18nEnv || global?.i18nEnv;
  const _defaultEnv = realEnv || 'zh';

  // 先注册一个默认语言
  const lang = getLanguageFromCache(_defaultEnv);
  this.setLanguage(_defaultEnv, lang);

  
}

// 获取语言环境
// I18n.prototype.getEnv = function () {
//   return this.env;
// };

// 设置语言环境
I18n.prototype.setLanguage = function (env, lang) {
  // 空对象可以设置, 中文允许空对象
  if (!env || !lang) return;
  // 是否启用国际化
  this.env = env;
  this.lang = lang;

  // 全局环境注册
  // if (window) window.i18nEnv = env;
  // if (global) global.i18nEnv = env;
};

// 获取语言包
I18n.prototype.getLang = function () {
  return this.lang;
};

/**
 * 初始化
 * @param {*} env 环境
 */
I18n.prototype.init = async function (env) {
  const oldEnv = this.env;

  const retData = await getLanguage(env);
  if (retData.error) {
    return Promise.reject(this.render({ key: retData.error }));
  }

  // 设置环境
  this.setLanguage(retData.langType, retData.langObj);

  // 重新加载页面
  if (oldEnv !== this.env) {
    

    window.location.reload();
    
  }
};

/**
 * render函数
 * @param {*} config 参数；如果是string，认为是key；否则是config
 * @param {*} config.key 国际化配置文件对象的key值，可以是普通结果值，也可以是标准key；
 * @param {*} config.defaultValue 非必须，如果没有对应的key配置，返回默认值
 * @param {*} config.object 非必须，兜底方案字段，配合type使用。拼接出key：'object.type.首页'；layout\options\表名\static\http
 * @param {*} config.type 非必须，兜底方案字段，配合object使用。title\actions\label(field)\message
 * @param {*} config.other 非必须，兜底方案字段，配合object使用。只有在少数情况下使用，如果有结构是：object.type.other.首页
 * 渲染逻辑：
 * 1. 如果没有key，返回默认值，如果没有默认值，返回空。
 * 2. 先使用object/type/other拼接新的newKey，查找，如果查到，返回结果；如果查不到，执行3
 * 3. 如果newkey没有查到，尝试用参数key查找，如果找到，返回结果；如果查不到，执行4
 * 4. 查看是否有默认值，有默认值，返回默认值；如果没有，返回key。
 */
function i18nRender(config) {
  let key = '';
  let object = '';
  let type = '';
  let defaultValue = '';
  let other = '';
  if (typeof config === 'string') {
    key = config;
  } else {
    key = config.key;
    object = config.object;
    type = config.type;
    defaultValue = config.defaultValue;
    other = config.other;
  }
  if (key) {
    // 先拼接
    if (object || type || other) {
      let newKey = '';
      if (object) {
        newKey += `${object}.`;
      }
      if (type) {
        newKey += `${type}.`;
      }
      if (other) {
        newKey += `${other}.`;
      }
      newKey += key;
      const result = this.findResultFromKey(newKey);
      if (result) return result;
    }
    // 通过newkey没找到，尝试用原始Key查找
    const result = this.findResultFromKey(key);
    if (result) return result;

    // 都没找到，默认值
    if (defaultValue) return defaultValue;

    // 都没找到，也没有默认值,返回自身
    return key;
  }
  if (defaultValue) return defaultValue;
  return '';
}
I18n.prototype.render = i18nRender;
/**
 * 根据key在当前语言包中查找结果
 * @param {*} key i18n.nges.xx.xx
 */
I18n.prototype.findResultFromKey = function (key) {
  if (!key) return '';
  let result = '';
  try {
    result = this.lang[key];
    return result;
  } catch (e) {
    console.log('e');
  }
};

/**
 * todo 反向查询，多对多不可靠
 * 根据value和当前环境查找key，再根据key找结果
 * @param {*} value 显示的文字 '标题'
 */
/*
I18n.prototype.findResultFromValue = function (value) {
  let _key = '';
  // 真实值，先查找key，先从当前语言包找，找不到再从其他语言包找
  Object.keys(this.lang).forEach((item) => {
    if (this.lang[item] === value) {
      _key = item;
    }
  });
  if (!_key) {
    // todo 可支持更多语言
    let otherLang = {};
    if (this.env === 'en') {
      otherLang = this.zhLang;
    } else {
      otherLang = this.enLang;
    }

    Object.keys(otherLang).forEach((item) => {
      if (otherLang[item] === value) {
        _key = item;
      }
    });
  }

  return this.findResultFromKey(_key, value);
};
*/

/**
 * 获取国际化图片值
 * @param {*} obj 当前key包含的对象
 * @param {*} key 请在合适的位置加上(% -> _en_i18n)
 * @description 参考函数renderImageKey, obj = {image:'url1', image_en_i18n:'url2'}, key: image, 返回url2
 */
I18n.prototype.renderImage = function (obj = {}, key = '') {
  const i18nImageKey = this.renderImageKey(key);
  return obj[i18nImageKey] || obj[key];
};

/**
 * 获取国际化图片的key值(key 例如 image, 会变成image_en_i18n)
 * @param {*} key 请在合适的位置加上(% -> _en_i18n)
 * @description 只会替换第一个%, 如果不存在%默认追加到后面
 * @description image -> image_en_i18n
 * @description ①image% -> image_en_i18n  ②im%age -> im_en_i18nage
 */

I18n.prototype.renderImageKey = function (key = '') {
  const i18nImage = `_${this.env}_i18n`;
  if (key.indexOf('%') === -1) {
    return key + i18nImage;
  }
  return key.replace('%', i18nImage);
};

I18n.prototype.getSystemLanguage = function () {
  // 环境决定 > 角色决定 > 应用决定 > 默认兜底。
  
  return null;
};

const i18n = new I18n();
export default i18n;
export { i18nRender };
