// 元数据对象跳转action
export const NAVIGATION_ACTION = ['TO_CREATE', 'TO_EDIT', 'TO_LIST', 'TO_DETAIL'];

// 元数据对象操作
export const COMMON_GRAPHQL_ACTION = [
  'GRAPHQL_CREATE',
  'GRAPHQL_UPDATE',
  'GRAPHQL_DELETE',
  'GRAPHQL_EXEC',
  'GRAPHQL_DCR_CREATE',
  'GRAPHQL_DCR_UPDATE',
];

// 前置ACTION支持的标准回调ACTION
export const CONFIRM_ACTION = ['ALERT', 'CONFIRM'];

// 通知action
export const NOTICE_ACTION = ['NOTICE'];

// Action cmd默认前缀
export const ACTION_CMD_PREFIX = 'FaasGatewayServer/func/';

// Message action 前缀
export const ACTION_MSG_PREFIX = 'MSG:';

// 元数据对象跳转action对应的标准布局页面路径
export const NAVIGATION_ACTION_MAP_TEMPLATE_LAYOUT_PATH = {
  edit: '/pages/layout/mutation/index',
  create: '/pages/layout/mutation/index',
  list: '/pages/layout/index/index',
  detail: '/pages/layout/detail/index',
};

// 自定义选择和提交action
export const SELECT_CONFIRM = ['SELECT_CONFIRM'];

export const VARIATE_ACTION = ['SET_VARIATE', 'GET_VARIATE'];
