//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { has, isArray, get, isPlainObject, isNil, isEmpty } from 'lodash';
import { execExpression, isExpression } from 'nges-common/src/layout/expression';

import lInput from './components/input';
import lSelect from './components/select';
import lDate from './components/date';
import { componentTypeAdjust } from 'nges-common/src/layout/mutation';

export default {
  name: 'FormItem',
  components: {
    lInput,
    lSelect,
    lDate,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    column: {
      type: Object,
      default: () => {},
    },
    objects: {
      type: Array,
      default: () => [],
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    // 表单变更对象
    mutations: {
      type: [Object, void 0],
      default: void 0,
    },
    index: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: {},
      errors: {},
      componentTypeAdjust,
    };
  },
  computed: {
    mutation() {
      return this.column?.mutation;
    },
    formValue() {
      return this.getValue();
    },
    formData() {
      const formData = this.getData();
      return Array.isArray(formData) ? [...formData] : formData;
    },
    modelFormValue() {
      return {
        [this.getUpdateField()]: this.formValue,
      };
    },
  },
  mounted() {
    this.initRules();
    this.initData();
    this.$emit('form-ready');
  },
  methods: {
    resetFields() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
    getUpdateField() {
      let { field } = this.column;
      // 有层级代表是树状结构列表
      if (this.row.$height) {
        const fieldStrList = field.split('.');
        field = fieldStrList[fieldStrList.length - 1];
      } else {
        field = field.split('.').join('-');
      }
      return field;
    },
    mergeForm({ key, value, data }) {
      console.log('meowkpd2', { key: this.row.$rowKey, prop: key, data, value });
      this.$emit('update-data', { key: this.row.$rowKey, prop: key, data, value });
    },
    handleChange({ value, data }) {
      const field = this.getUpdateField();
      this.mergeForm({ key: field, value, data });
      if (isExpression(this.mutation?.change)) {
        execExpression(this.mutation.change, {
          $value: this.formValue,
          $data: this.formData,
          $row: this.row,
          t: {
            formData: this.row,
          },
          m: {
            mergeForm: ({ key, data, value }) => {
              this.mergeForm({ key, data, value });
            },
          },
        });
      }
    },
    getValue() {
      const field = this.getUpdateField();
      const { json: listLayoutJson } = this.mutation || {};
      const getListValue = (item) =>
        item?.value ?? get(item, listLayoutJson?.render?.checkbox?.value_key || 'id');
      if (['SELECT_MANY'].includes(this.mutation?.value_type)) {
        let data = this.row[field];
        if (isArray(data)) {
          if (listLayoutJson) {
            data = data.map((i) => {
              return getListValue(i);
            });
          } else {
            data = data.map((i) => {
              return has(i, 'value') ? i?.value : i;
            });
          }
        } else {
          data = isNil(data) || isEmpty(data) ? void 0 : [data];
          if (listLayoutJson && Array.isArray(data)) {
            data = data.map((i) => {
              return getListValue(i);
            });
          }
        }
        return data;
      }
      if (['SELECT_ONE'].includes(this.mutation?.value_type)) {
        if (listLayoutJson) {
          return getListValue(this.row[field]);
        }
        if (has(this.row?.[field], 'value')) {
          return this.row[field]?.value;
        }
        return this.row[field];
      }
      return this.row[field];
    },
    getData() {
      const field = this.getUpdateField();
      return this.row[field];
    },
    initRules() {
      const { field } = this.column;
      const { rules: fieldRules = [] } = this.mutation;
      if (fieldRules) {
        const customFieldRules = [];
        const normalFieldRules = [];
        fieldRules.forEach((rule = {}) => {
          if (rule.type === 'custom') {
            customFieldRules.push(rule);
          } else {
            normalFieldRules.push(rule);
          }
        });
        const customRules = this.formatCustomFieldRules(customFieldRules);
        this.rules = {
          [field]: [...normalFieldRules, ...customRules],
        };
      }
    },
    formatCustomFieldRules(rules = []) {
      return rules.map((rule) => {
        const { trigger } = rule;
        let validateTrigger = 'change';
        if (trigger !== void 0) {
          validateTrigger = trigger;
        }
        return {
          validator: this.getCustomRuleFuc({
            conditions: rule.conditions,
          }),
          trigger: validateTrigger,
        };
      });
    },
    getCustomRuleFuc({ conditions = [] }) {
      return (rule, value, callback) => {
        try {
          const len = conditions.length;
          if (len) {
            for (const conditionIndex in conditions) {
              const condition = conditions[conditionIndex];
              if (this.adjustIsValid(condition.condition, value)) {
                callback(new Error(condition.message));
                break;
              }
            }
          }
          callback();
        } catch (err) {
          console.error(err);
          callback(new Error(`自定义条件判断失败，请检查表达式是否正确！: ${err.toString()}`));
        }
      };
    },
    adjustIsValid(condition, value) {
      return execExpression(condition, {
        $value: value,
        ...this.expParamsObj,
        $item: this.row,
        t: {
          ...(isPlainObject(this.expParamsObj?.t) ? this.expParamsObj.t : {}),
          formValue: this.formValue,
          formData: this.formData,
        },
      });
    },
    initData() {
      const field = this.getUpdateField();
      if (this.$refs?.fitem?.getData) {
        const data = this.$refs.fitem.getData({
          field,
          row: this.row,
        });
        this.$emit('update-data', { key: this.row.$rowKey, prop: field, data });
      }
    },
    checkRules() {
      return new Promise((re) => {
        if (this.$refs.form) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              return re(true);
            }
            return re(false);
          });
        }
        return re(true);
      });
    },
  },
};
