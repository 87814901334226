//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tmpFeedback() {
      return this.message?.tmpFeedback || '';
    },
  },
  methods: {
    async setStatus(feedback) {
      this.$emit('message-change', {
        ...this.message,
        tmpFeedback: feedback,
      });
      if (feedback) {
        if (feedback === 'negative') {
          this.$emit('feedback-change', {
            ...this.message,
          });
        } else {
          this.$emit('feedback-confirm', {
            ...this.message,
            feedback: 'positive',
            feedbackContent: '',
          });
        }
      }
    },
  },
};
