//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isPlainObject, isArray, has, get, cloneDeep, isNil } from 'lodash';
import { getFilter, filterOptions } from 'nges-common/src/layout/util';
import { getValue } from 'nges-common/src/graphql';
import { execExpression, execExpressionDeep } from 'nges-common/src/layout/expression';
import { easyQueryGql } from 'nges-common/src/layout/queryUtil';
import { DataService } from 'nges-common/src/api';
export default {
  name: 'ListSelectMany',
  props: {
    mutation: {
      type: Object,
      default: () => {},
    },
    formValue: {
      type: [Array, Object, Number, String, void 0],
      default: void 0,
    },
    formData: {
      type: [Array, Object, Number, String, void 0],
      default: void 0,
    },
    objects: {
      type: Array,
      default: () => [],
    },
    expParamsObj: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: [String, Number],
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      listVisible: false,
      customCondition: '',
      tmpData: [],
      dialogVisible: false,
      options: [],
      isFocus: false,
      tmpValue: '', // 用于记录临时选中值,仅用于展示
    };
  },
  computed: {
    listLayoutJson() {
      return this.mutation?.json;
    },
    valueKey() {
      return this.listLayoutJson?.render?.checkbox?.value_key || 'id';
    },
    labelKey() {
      return this.listLayoutJson?.render?.checkbox?.label_key;
    },
    defaultValue() {
      return this.mutation?.default_value;
    },
    canPreview() {
      return ['ALL', 'FIRST', 'LAST'].includes(this.defaultValue);
    },
    descsTag() {
      return this.mutation?.descs_tag;
    },
    selectTag() {
      return {
        label: execExpression(this.descsTag?.label, this.calcExpParamsObj),
        type: execExpression(this.descsTag?.type, this.calcExpParamsObj),
      };
    },
    showSelectTag() {
      return (
        this.descsTag && (this.formValue || typeof this.formValue === 'number') && !this.isFocus
      );
    },
    calcExpParamsObj() {
      return {
        ...this.expParamsObj,
        $item: this.row,
        $index: this.index,
      };
    },
    descDelimiter() {
      return this.mutation?.desc_delimiter || '';
    },
    descKey() {
      return this.listLayoutJson?.render?.checkbox?.option_desc;
    },
  },
  watch: {
    formData() {
      if (this.listLayoutJson) {
        this.initOptions();
      }
    },
  },
  mounted() {
    this.initOptions();
    this.initDefaultValue();
  },
  methods: {
    get,
    initOptions() {
      let options = [];
      if (['SELECT_MANY', 'SELECT_ONE'].includes(this.mutation?.value_type)) {
        options = this.getOptions();
      }
      this.options = options;
    },
    initDefaultValue() {
      if (this.mutation?.value_type === 'SELECT_ONE') {
        this.tmpValue = this.formValue;
      }
    },
    getOptions() {
      let optionsFrom = this.mutation?.options_from;
      let render = this.mutation?.render || {};
      const filter = this.mutation?.filter || {};
      render = { ...filter, ...render };
      optionsFrom = render.options_from || optionsFrom;
      if (render.options && render.options.length !== 0) {
        return execExpressionDeep(cloneDeep(render.options), this.calcExpParamsObj) || [];
      }
      if (optionsFrom) {
        if (isPlainObject(optionsFrom) && optionsFrom.type === 'META_DATA') {
          const { options: optionConfig = {} } = optionsFrom;
          const optionsFromMetaData = [];
          if (optionConfig.select_option) {
            const filter = optionConfig.select_option || {};
            const gql = easyQueryGql({
              ...filter,
              object: optionConfig.object,
              wheres: filter.wheres || [],
              orders: filter.orders || [],
              fields: [
                filter.label,
                filter.value,
                ...(Array.isArray(filter.option_desc) ? filter.option_desc : [filter.option_desc]),
              ].filter((item) => !isNil(item)),
            });
            DataService({
              query: gql,
            })
              .then((res) => {
                const options = filterOptions({ ...filter, object: optionConfig.object }, res);
                if (options?.[0]?.label === '全部' && options[0]?.value === 'all') {
                  options.shift();
                }
                optionsFromMetaData.push(...options);
              })
              .catch(() => {});
          } else {
            (optionConfig.fields || []).forEach((field) => {
              const { options = [] } =
                getFilter({
                  filter: {
                    field,
                  },
                  objects: this.objects,
                  json: {
                    object: optionConfig.object,
                  },
                }) || {};
              if (options?.[0]?.label === '全部' && options[0]?.value === 'all') {
                options.shift();
              }
              optionsFromMetaData.push(...options);
            });
          }
          return optionsFromMetaData;
        }
      } else if (this.listLayoutJson) {
        let options = this.formData;
        if (isPlainObject(options)) {
          options = [options];
        }
        if (isArray(options)) {
          return options.map((option) => ({
            ...option,
            label: option?.label ?? get(option, this.labelKey) ?? '-',
            value: option?.value ?? get(option, this.valueKey),
            option_desc: isArray(this.descKey)
              ? this.descKey.map((desc) => {
                  return get(option, desc || '');
                })
              : get(option, this.descKey),
          }));
        }
      }
      return [];
    },
    handleChange(e) {
      let data = e;
      if (['SELECT_MANY'].includes(this.mutation?.value_type)) {
        data = this.options.filter((option) => {
          if (e.includes(option.value)) {
            return true;
          }
          return false;
        });
      } else if (['SELECT_ONE'].includes(this.mutation?.value_type)) {
        data = this.options.filter((option) => {
          if (e === option.value) {
            return true;
          }
          return false;
        });
        data = data?.[0];
      }
      this.$emit('handleChange', {
        data,
      });
    },
    getData({ field, row }) {
      if (['SELECT_MANY'].includes(this.mutation?.value_type)) {
        let data = row?.[field];
        if (isArray(data)) {
          data = data.map((i) => (has(i, 'value') ? i.value : get(i, this.valueKey)));
        } else {
          data = [data];
        }
        data = this.options.filter((option) => {
          if (data.includes(option.value)) {
            return true;
          }
          return false;
        });
        return data;
      }
      if (['SELECT_ONE'].includes(this.mutation?.value_type)) {
        let data = row?.[field];
        if (isPlainObject(data)) {
          if (has(data, 'value')) {
            data = data.value;
          } else {
            data = get(data, this.valueKey);
          }
        } else if (isArray(data)) {
          data = data?.[0];
        }
        data = this.options.filter((option) => {
          if (data === option.value) {
            return true;
          }
          return false;
        });
        data = data?.[0];
        return data;
      }
    },
    selectionChange(val) {
      this.tmpData = val;
      if (this.mutation?.value_type === 'SELECT_ONE') {
        this.tmpValue = get(val, this.valueKey);
      }
    },
    close() {
      this.listVisible = false;
      this.tmpData = [];
    },
    save() {
      const data = cloneDeep(this.tmpData);
      this.$emit('handleChange', { data });
      this.close();
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.initOptions();
        });
      });
    },
    visibleChange(e) {
      if (this.listLayoutJson && e) {
        this.listVisible = e;
        this.$nextTick(() => {
          if (!this.formValue?.length) {
            this.$refs?.list?.$refs?.list?.$refs?.list?.clearSelection();
          }
        });
      }
      this.isFocus = e;
    },
    setSelected() {
      if (!this.listLayoutJson.cmd) {
        if (!this.customCondition) {
          if (this.tmpData.length) {
            this.customCondition = `${this.valueKey}: { _in: ${getValue(
              this.tmpData.map((i) => get(i, this.valueKey)),
            )} }`;
          }
        } else {
          this.customCondition = '';
        }
        this.$nextTick(() => {
          this.$refs.dialogList.searchOnSubmit();
        });
      }
    },
    removeAllOrNot() {
      this.dialogVisible = true;
    },
    removeAll() {
      this.tmpData = [];
      this.dialogVisible = false;
      this.$refs?.dialogList?.$refs?.list?.clearSelection();
      this.setSelected();
    },
    handleGetDataSuccess(list) {
      switch (this.defaultValue) {
        case 'ALL': {
          if (this.mutation.value_type === 'SELECT_MANY') {
            this.tmpData = list;
            this.save();
          }
          this.$emit('resetFields');
          break;
        }
        case 'FIRST': {
          break;
        }
        case 'LAST': {
          break;
        }
      }
    },
  },
};
