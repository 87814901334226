import dayjs from 'dayjs';
import { getKvConfigs } from '@/graphql/api/meeting';

export const CHAT_BOT_DEFAULT_ICON =
  'https://cdn.nges.tencent.com/nges_1/31214202/20fdfbbe-2047-441b-983b-c10944892794.png';

let gChatbotConfig;
export async function getChatbotConfig() {
  if (!gChatbotConfig) {
    try {
      const res = await getKvConfigs({ keys: ['ai_chatbot_config'] }).catch(() => null);
      const value = res?.kv?.find((i) => i.key === 'ai_chatbot_config')?.value;
      gChatbotConfig = JSON.parse(value)?.web_config;
    } catch (error) {}
  }
  return gChatbotConfig;
}

// 获取前一个半小时和后一个半小时的时间戳
function getPrevHalfHours(timestamp) {
  const msPerMinute = 60 * 1000;
  const msPerHalfHour = 30 * msPerMinute;
  const prevHalfHour = timestamp - (timestamp % msPerHalfHour);
  return prevHalfHour;
}

// 控制显示日期时间的时机，处在同个半小时区间内的消息，只在消息顶部展示一个日期时间
export function formatMessagesTime(messages) {
  if (!messages?.length) {
    return;
  }
  let i = 0;
  const len = messages.length;
  const arr = [];
  let prevHalfHour = null;
  while (i < len) {
    const msg = messages[i];
    msg._show_time = null;
    // this.$set(mes, '_show_time', null);
    const mesPrevHalfHour = getPrevHalfHours(msg.time);
    if (mesPrevHalfHour !== prevHalfHour) {
      msg._show_time = dayjs(msg.time).format('YYYY/MM/DD HH:mm');
      // this.$set(mes, '_show_time', dayjs(msg.time).format('YYYY/MM/DD HH:mm'));
      arr.push(dayjs(msg.time).format('YYYY/MM/DD HH:mm'));
      prevHalfHour = mesPrevHalfHour;
    }
    i = i + 1;
  }
  // console.log(arr, arr.length);
  // const arr2 = [...new Set(arr)];
  // console.log(arr2.length);
  // console.log(`${i}条数据`);
  // this.canLoad = true;
}

export function parseStreamData(streamText, chunkTextFunc) {
  // const thinkingIdx = this.messages.findIndex((item) => {
  //   return item === thinkingMes;
  // });
  // if (thinkingIdx !== -1) {
  //   this.messages.splice(thinkingIdx, 1);
  // }
  // // const text = decodeURIComponent(escape(String.fromCharCode(...Array.from(res.data))));
  // const text = Buffer.from(res.data).toString('utf8');
  let cacheText = '';
  let replyText = '';
  let source = [];
  let cacheChatId;
  const textArr = streamText?.split('\n') || [];
  let jsons = [];
  textArr.forEach((item) => {
    try {
      const json = JSON.parse(item);
      jsons.push(json);
    } catch (e) {
      // 如果 json文本 被截断放在不同的 chunk 里，需要保存上半段，然后下一次解析（也会报错）带上这上半段
      if (cacheText) {
        try {
          const json = JSON.parse(cacheText + item);
          jsons.push(json);
          cacheText = '';
        } catch (e) {
          cacheText = cacheText + item;
        }
      } else {
        cacheText = item;
      }
    }
  });
  jsons = jsons.map((item) => {
    return item.body.payload;
  });
  if (jsons.length) {
    cacheChatId = jsons[0].chat_id;
    jsons.forEach((resp) => {
      if (!resp.response) {
        return;
      }
      const text = resp.response.abstract;

      replyText = replyText + text;

      const responseSource = resp.response.source;
      if (responseSource) {
        source = source.concat(responseSource);
      }

      if (chunkTextFunc) {
        chunkTextFunc({ chat_id: cacheChatId, text: replyText, source });
      }
    });
  }
  if (chunkTextFunc) {
    // 完成了
    chunkTextFunc({ chat_id: cacheChatId, text: replyText, isFinished: true });
  }

  return replyText;
}

export async function parseStreamDataAsync(streamText, botSpeed, chunkTextFunc) {
  return new Promise((resolve) => {
    let chunkCount = -1;
    parseStreamData(streamText, async (chunkData) => {
      chunkCount = chunkCount + 1;
      await sleepMs(chunkCount * (botSpeed || 100));

      if (chunkData.isFinished) {
        resolve(chunkData.text);
      } else {
        chunkTextFunc(chunkData);
      }
    });
  });
}

export function sleepMs(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
