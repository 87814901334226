function msgAction({ action, action_params: actionParams = {} }) {
  return new Promise((resolve) => {
    const msg = action.slice(4);
    setTimeout(() => {
      uni.showToast({
        title: msg,
        duration: actionParams?.duration || 20000,
        icon: 'none',
      });
    });
    resolve();
  });
}

export { msgAction };
