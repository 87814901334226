//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cloneDeep } from 'lodash';
import { execExpressionDeep } from 'nges-common/src/layout/expression';
import { layoutRequest } from 'nges-common/src/layout/layoutRequest';
import MProgress from '../progress';

const _ = { cloneDeep };

export default {
  name: 'MiniDashboardCardChild',
  components: {
    MProgress,
  },
  props: {
    json: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataSource: {},
      item: {},
      loading: true,
      fontSizeFinish: false,
      fontSize: 32,
      realFontSize: 32,
      breakWord: false,
    };
  },
  computed: {
    expParamsObj() {
      return {
        t: {
          ...this.dataSource,
        },
        p: this.params,
      };
    },
    itemText() {
      if (!this.fontSizeFinish) return '-';
      return this.split(this.item.text) || '-';
    },
    fakeItemText() {
      return this.split(this.item.text) || '-';
    },
  },
  methods: {
    init() {
      const json = _.cloneDeep(this.json);
      json.ignore_id = true;
      if (json.cmd || json.object) {
        return layoutRequest(json, this.expParamsObj)
          .then((value) => {
            if (json.object) {
              const data = {
                list: value.formattedValues,
                aggregate: value.aggregate,
              };
              this.dataSource = data;
              if (json.key) {
                return {
                  key: json.key,
                  value: data,
                };
              }
              return undefined;
            }
            this.dataSource = value.remoteValues;
            if (json.key) {
              return {
                key: json.key,
                value: value.remoteValues,
              };
            }
            return undefined;
          })
          .catch((e) => {
            console.log(e);
            return undefined;
          });
      }
      this.dataSource = {};
      return Promise.resolve(undefined);
    },
    render() {
      this.loading = true;
      if (this.json?.hidden && execExpressionDeep(this.json.hidden, this.expParamsObj)) return;
      this.item = execExpressionDeep(_.cloneDeep(this.json.render), this.expParamsObj);
      this.loading = false;
      if (this.item.scale) {
        this.$nextTick(() => {
          this.fontSizeChange();
        });
      } else {
        this.fontSizeFinish = true;
      }
    },
    split(number) {
      if (typeof number !== 'number') return number;
      const str = number.toString();
      if (str.length < 4) return str;
      const first = str.length % 3;
      let result = str.slice(0, first);
      for (let i = first; i < str.length; i += 3) {
        if (i !== 0) result += ',';
        result += str.slice(i, i + 3);
      }
      return result;
    },
    queryElementPromise(className, required = true) {
      return new Promise((resolve, reject) => {
        if (this.$el.getElementsByClassName(className)[0]) {
          resolve(this.$el.getElementsByClassName(className)[0].getBoundingClientRect().width);
        } else if (required) {
          reject();
        } else {
          resolve(0);
        }
      });
    },
    async fontSizeChange() {
      try {
        const [allWidth, textWitdh, suffixWidth] = await Promise.all([
          this.queryElementPromise('fake-child-right'),
          this.queryElementPromise('fake-child-text'),
          this.queryElementPromise('fake-child-suffix', false),
        ]);
        if (allWidth < textWitdh + suffixWidth + 10) {
          const nextNumber = this.fontSize - 2;
          if (nextNumber < 18) {
            this.realFontSize = this.fontSize;
            this.fontSizeFinish = true;
            this.breakWord = true;
            return;
          }
          this.fontSize -= 2;
          this.$nextTick(() => {
            this.fontSizeChange();
          });
        } else {
          this.realFontSize = this.fontSize;
          this.fontSizeFinish = true;
        }
      } catch (e) {
        console.log(e);
        this.realFontSize = 32;
        this.fontSizeFinish = true;
      }
    },
  },
};
