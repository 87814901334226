import { DataService } from '../../api';
import { toMutationGql } from '../../layout/toMutationGql';

function graphqlActionRequest(action) {
  return new Promise((resolve, reject) => {
    const actionName = action?.action;
    const actionParams = action?.action_params || {};
    const { object, id, fields, subFields, graphql_str: graphqlStr } = actionParams;
    let gqlSql = '';
    if (actionName === 'GRAPHQL_CREATE') {
      gqlSql = toMutationGql({ actionName, object, fields, id, action: 'insert' });
    } else if (actionName === 'GRAPHQL_UPDATE') {
      gqlSql = toMutationGql({ actionName, object, fields, id, action: 'update' });
    } else if (actionName === 'GRAPHQL_DELETE') {
      gqlSql = toMutationGql({ actionName, object, id, action: 'delete' });
    } else if (actionName === 'GRAPHQL_DCR_CREATE') {
      gqlSql = toMutationGql({ actionName, object, fields, subFields, id, action: 'insert' });
    } else if (actionName === 'GRAPHQL_DCR_UPDATE') {
      gqlSql = toMutationGql({ actionName, object, fields, subFields, id, action: 'update' });
    } else {
      gqlSql = graphqlStr;
    }
    return DataService({
      query: gqlSql,
    })
      .then((res) => {
        resolve(res?.[object]?.[0] || res);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export { graphqlActionRequest };
