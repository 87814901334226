import i18n from 'nges-common/src/i18n/index';
import { globalConfigDialog } from './confirm-dialog/util';

function confirmAction(action) {
  return new Promise((resolve) => {
    const { action_params: actionParams, action: actionName } = action;
    if (actionParams?.disable_time) {
      const open = globalConfigDialog.open[globalConfigDialog.open.length - 1];
      if (open) {
        open({
          ...actionParams,
          handleClose: () => resolve(false),
          handleConfirm: () => resolve(true),
        });
        return;
      }
    }
    const defaultColor = {
      confirm: '#0D7CFF',
      del: '#FF4B45',
      cancel: '#4E5761',
    };
    if (global.themeCssVar) {
      defaultColor.confirm = global.themeCssVar['--Uni-Modal-Confirm'] || '#0D7CFF';
      defaultColor.del = global.themeCssVar['--Uni-Modal-Del'] || '#FF4B45';
      defaultColor.cancel = global.themeCssVar['--Uni-Modal-Cancel'] || '#4E5761';
    }
    uni.showModal({
      title: i18n.render({ key: actionParams?.title || '', object: 'layout' }),
      content: i18n.render({ key: actionParams?.content || ' ', object: 'layout' }),
      success: (res) => {
        if (res.confirm) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      showCancel: actionName === 'CONFIRM',
      confirmText: i18n.render({ key: actionParams?.confirm_text || '确定', object: 'layout' }),
      cancelText: i18n.render({ key: actionParams?.cancel_text || '取消', object: 'layout' }),
      confirmColor: actionParams?.confirm_color
        ? actionParams.confirm_color
        : actionParams?.confirm_text?.indexOf('删除') !== -1
        ? defaultColor.del
        : defaultColor.confirm,
      cancelColor: actionParams?.cancel_color ? actionParams.cancel_color : defaultColor.cancel,
    });
  });
}

export { confirmAction };
