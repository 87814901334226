//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSystemInfo } from 'nges-common/src/util';
import ErrorTips from './error-tips.vue';
export default {
  options: { styleIsolation: 'shared' },
  components: { ErrorTips },
  props: {
    inPopup: {
      type: Boolean,
      default: false,
    },
    errorNumber: {
      type: Number,
      default: 1,
    },
    errorCount: {
      type: Number,
      default: 0,
    },
    footerNotFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: 100,
      isIOS: false,
    };
  },
  watch: {
    errorCount(newVal, oldVal) {
      if (newVal || (!newVal && oldVal)) {
        this.setBottomHeight();
      }
    },
  },
  mounted() {
    const { isIOS } = getSystemInfo();
    this.isIOS = isIOS;
    this.setBottomHeight();
  },
  methods: {
    // 由于布局中，popup打开编辑布局的情况下，会导致第一次取不到高度，故这里延迟500ms，待popup完全打开后，再获取一次。
    async setBottomHeight(isSecond) {
      await this.$nextTick();
      const query = this.createSelectorQuery().in(this);
      query.select('#bottom-action__content_container').boundingClientRect((res) => {
        console.log('meowhei', res?.height);
        if (!res?.height && !isSecond) {
          setTimeout(() => {
            this.setBottomHeight(true);
          }, 500);
        } else {
          this.height = res.height;
        }
      });
      query.exec();
    },
    handleClickPreviousNextBtn(data) {
      this.$emit(`previous-next-error`, data);
    },
  },
};
