export default {
  namespaced: true,
  getters: {},
  state: {
    sideMenuCollapse: false,
  },
  mutations: {
    setSideMenuCollapse(state, val) {
      state.sideMenuCollapse = val;
    },
  },
  actions: {},
};
