//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import QuestionList from './components/message-comp/question-list';
import TextSourceComp from './components/message-comp/text-source';
import {
  getChatbotConfig,
  CHAT_BOT_DEFAULT_ICON,
  formatMessagesTime,
  parseStreamDataAsync,
} from './utils/chatbot';
import MsgCreator from './utils/msg-creator';
// import { AiChatStream } from '@/api/ai';
import { streamRequest } from 'nges-common/src/stream-request-web.js';
import Clipboard from 'clipboard';
import _ from 'lodash';

export default {
  components: {
    QuestionList,
    TextSourceComp,
    // ThinkingComp,
    // FeedBack,
    // NoPermission,
    // FeedbackPopup,
  },
  props: {
    // 来源页面
    pageFrom: {
      type: String,
      default: '',
    },
    // AI前置请求参数 比如 { request: '正文内容' }
    beforeRequestPramasFunc: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isInit: false,
      visible: false,
      visibleAnimated: false, //
      loading: false,
      messages: [],
      config: null,
      msgCreator: null,
      copyText: '',
      isFloatButtonShow: false,
    };
  },
  computed: {
    chatbotName() {
      return this.config?.name ?? '智能助手';
    },
    chatbotIcon() {
      return this.config.img ?? CHAT_BOT_DEFAULT_ICON;
    },
    questionEntry() {
      return this.msgCreator.getQuestionEntry();
    },
  },
  watch: {
    visible(val) {
      this.$emit('visible-changed', val);
    },
  },
  created() {},
  mounted() {
    // this.open();
    const _this = this;
    this.setupEventListeners('keydown', (e) => {
      if (e.keyCode === 27) {
        _this?.handleClose();
      }
    });
  },
  beforeDestroy() {
    this.setupEventListeners('keydown', null);
  },
  methods: {
    open() {
      if (this.visible) {
        return;
      }
      this.visible = true;
      this.scrollToBottom();
      setTimeout(() => {
        this.visibleAnimated = true;
      }, 50);
      this.init();
    },
    async init() {
      if (this.isInit) {
        return;
      }

      this.loading = true;
      try {
        await this.initKvConfig();
      } catch (error) {
        console.error(error);
      }
      this.isInit = true;
      this.loading = false;
    },
    async initKvConfig() {
      const config = await getChatbotConfig();
      if (!config) {
        this.handleClose();
        this.message?.error('AI配置错误, 请联系管理员');
        return;
      }
      this.config = config;
      this.msgCreator = new MsgCreator({
        config: this.config,
        messages: this.messages,
        pageFrom: this.pageFrom,
      });

      // 欢迎语
      this.msgCreator.createWelcomeMsg();

      // 问题列表
      this.msgCreator.createQuestionListMsg();

      this.refreshMsgShowTime();
    },
    setupEventListeners(type, listener) {
      if (!type) {
        return;
      }
      const thisKey = `_${type}ListenerFunc`;
      const thisKeyFunc = this[thisKey];
      // 先移除绑定
      if (thisKeyFunc) {
        window.removeEventListener(type, thisKeyFunc);
        this[thisKey] = null;
      }

      if (listener) {
        document.addEventListener(type, listener);
        this[thisKey] = listener;
      }
    },
    async sendQuickMessage({ mode, item }) {
      const msg = this.msgCreator.createMsg({ from: 'user', params: item });
      if (mode === 'message') {
        // 消息入口 需要清掉当前消息
        this.msgCreator.removeQuestionListMsg();
        this.isFloatButtonShow = true;
      }
      this.scrollToBottom();
      await this.getBotResponse(msg);
      this.refreshMsgShowTime();
      this.scrollToBottom();
    },
    async getBotResponse(message, { isRetry = false } = {}) {
      if (!message) {
        return;
      }
      const { action } = message.params || {}; // 命令 1:请求文章简版摘要 2:请求文章全文摘要
      //
      if (action !== undefined || action === null) {
        let newMessage;

        if (isRetry) {
          newMessage = message;
          newMessage.request.loading = true;
          newMessage.response = {
            reply: null,
          };
        } else {
          newMessage = this.msgCreator.createMsg({
            from: 'bot',
            params: message.params,
          });
          newMessage.request.loading = true;
        }

        let requestData = {};
        if (this.beforeRequestPramasFunc) {
          // { request: '正文内容' }
          requestData = this.beforeRequestPramasFunc(message);
        }

        requestData = {
          cmd: action,
          ...requestData,
        };
        // const res = await AiChatStream(requestData).catch(() => null);
        const res = await streamRequest('McmAdminServer/AiService/ChatStream', requestData).catch(
          () => null,
        );

        const _this = this;
        // const data = JSON.parse(res.data);
        newMessage.time = new Date().getTime();
        newMessage.request.loading = false;
        await parseStreamDataAsync(res?.data, this.config.bot_speed_ms, (chunkData) => {
          // const index = _this.messages.indexOf(newMessage);
          newMessage.chat_id = chunkData.chat_id;
          newMessage.response.reply = [{ text: chunkData.text, photo: [] }];
          newMessage.response.source = chunkData.source;
          // _this.$set(_this.messages, index, newMessage);
          // console.log('哈哈哈', chunkData.text);
        });

        // res.data.on('data', (chunk) => {
        //   //
        //   console.log('哈哈哈chunk', chunk);
        // });
        // res.data.on('end', () => {
        //   //
        // });
        // console.log('哈哈哈', res);
      }
    },
    refreshMsgShowTime() {
      formatMessagesTime(this.messages);
    },
    async initCopyText() {
      // 初始化visible
      await this.$nextTick();
      if (this.clipInstance) {
        return;
      }

      this.clipInstance = new Clipboard(this.$refs.copyRef);
      this.clipInstance.on('success', () => {
        this.$message({
          message: '复制成功',
          type: 'success',
        });
      });
      this.clipInstance.on('error', (e) => {
        this.$message.error(`复制失败${e}`);
      });
    },
    handleClose() {
      if (!this.visibleAnimated || !this.visible) {
        return;
      }
      this.visibleAnimated = false;
      setTimeout(() => {
        this.visible = false;
      }, 300);
    },
    async handleAiAction(action) {
      const { actionName, message, msgText } = action || {};
      // retry import copy
      if (actionName === 'COPY_TEXT') {
        // 兼容不同浏览器，nextTick后再执行点击有兼容问题

        this.copyText = msgText;
        if (!this.copyText) {
          this.$message('当前没有可复制的文本');
          return;
        }

        await this.initCopyText();

        setTimeout(() => {
          this.$refs.copyRef.click();
        }, 200);
      } else if (actionName === 'RETRY') {
        this.getBotResponse(message, { isRetry: true });
      } else if (actionName === 'USE_TEXT') {
        this.$emit('action', { actionName, data: msgText });
      }
    },
    scrollToBottom: _.debounce(async function () {
      await this.$nextTick();
      const scrollView = this.$refs.scroll_view;
      if (!scrollView) {
        return;
      }
      scrollView.scrollTop = scrollView.scrollHeight;
    }),
  },
};
