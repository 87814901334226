/* eslint-disable */
import { isEqual } from 'lodash';
import { GetManagerLogin } from '@/api/login';
import { getManagerInfoApi, getPublishedAppListApi } from '@/api/setUser';
import {
  GetUserMenuTree,
  GetUserElementList,
  GetStaffUserInfo,
} from 'nges-common/src/web/utils/api';
import { getLayoutJson } from 'nges-common/src/layout/queryWeb';
import main from '@/main';
import config from '@/config';
import router from '@/router';
import { MENU_ROUTE } from '@/utils/constant';
import { GetTenantObjectsPermission } from '@/api/common';
import { NeedReadMessage } from '@/api/notice';
import { NeedReadFeedback } from '@/api/feedback';
import { query, getRouteName } from 'nges-common/src/web/utils/util';
import { helpGuideUtil } from 'nges-common/src/layout/util';
import { paramIsBreadcrumb } from '@/utils/util';

function matchRouter(menuPath, routerName, path, fullPath) {
  if (menuPath === routerName || menuPath === path) {
    return true;
  }
  let menuQueryPath = '';
  const match = query('path', menuPath);
  if (match) {
    menuQueryPath = match;
  }
  // 若存在path，则取其url中的pathname
  let pathName = menuPath;
  if (menuQueryPath) {
    const match = getRouteName(menuPath);
    if (match) {
      pathName = match;
    }
  }
  if (pathName === routerName && (!menuQueryPath || (menuQueryPath && menuQueryPath === path))) {
    return true;
  }
  if (menuPath === fullPath) {
    return true;
  }
  try {
    // 去除多余参数后再判断一次
    if (menuPath && fullPath) {
      const url1 = menuPath.split('?')[0];
      const url2 = fullPath.split('?')[0];
      if (url1 === url2) {
        const p1 = menuPath.split('?')[1].split('&');
        const p2 = fullPath.split('?')[1].split('&');
        const param1 = {};
        const param2 = {};
        p1.forEach((i) => {
          const [key, value] = i.split('=');
          if (paramIsBreadcrumb(key)) param1[key] = value;
        });
        p2.forEach((i) => {
          const [key, value] = i.split('=');
          if (paramIsBreadcrumb(key)) param2[key] = value;
        });
        return isEqual(param1, param2);
      }
    }
  } catch (e) {
    console.log(e);
    return false;
  }
  return false;
}

function findRouter(menuList, routerName, path, fullPath, breadcrumb) {
  for (let i = 0; i < menuList.length; i += 1) {
    const menu = menuList[i];

    // 先寻找自己的子菜单 如果看到了符合项 那么自己也是需要的菜单
    if (menu.children?.length) {
      const child = findRouter(menu.children, routerName, path, fullPath, breadcrumb);
      if (child) {
        breadcrumb.push({
          menu_name: menu.menu_name,
          menu_path: menu.menu_path,
        });
        return true;
      }
    }
    // 当前页面的菜单并非叶子结点 或自己没有子菜单的场合 如果自己就是正确的菜单 则直接返回自己
    if (matchRouter(menu.menu_path, routerName, path, fullPath)) {
      breadcrumb.push({
        menu_name: menu.menu_name,
        menu_path: menu.menu_path,
      });
      return true;
    }
  }
}

// 新菜单布局能力,处理菜单数据，与之前的逻辑兼容。
function setNewMenuPath(menu) {
  if (menu.type === 'object' && menu.object_code) {
    // 对象菜单
    menu.path = `/saas/layoutList.html?object=${menu.object_code}`;
  } else if (menu.type === 'external') {
    // 外部菜单
    menu.path = `${menu.url}${menu.params ? '?' : ''}${menu.params || ''}`;
  }
  return menu;
}

// 是否有打开当前页面 url 路径权限
// 目前仅在 web 定开判断时调用，一定是
function hasRouterRight(menuList, routerName) {
  const { rightFilter } = config;
  let menuItem = null;
  let rightRouterName = '';
  menuList.forEach((menu) => {
    if (menu.menu_path === routerName && !menuItem) {
      menuItem = menu;
    }
    if (menu.menu_path && !rightRouterName && !rightFilter.includes(menu.menu_path)) {
      rightRouterName = menu.menu_path;
    }
    if (menu.children && menu.children.length) {
      menu.children.forEach((menuChild) => {
        if (menuChild.menu_path === routerName && !menuItem) {
          menuItem = menuChild;
        }
        if (menuChild.menu_path && !rightRouterName && !rightFilter.includes(menu.menu_path)) {
          rightRouterName = menuChild.menu_path;
        }
      });
    }
  });
  return {
    hasRight: !!menuItem,
    rightRouterName,
  };
}

function transMenuList(list) {
  if (list && list.length) {
    return list.map((item) => {
      item = setNewMenuPath(item);
      return {
        path: item.path,
        menu_path: item.path,
        menu_name: item.name,
        menu_icon: item.icon,
        display: item.display,
        layout_json: item.layout_json,
        url: item.url,
        params: item.params,
        type: item.type,
        custom_type: item.custom_type,
        custom_layout_usage: item.custom_layout_usage,
        object_code: item.object_code,
        open_type: item.open_type,
        children: (item.children || []).map((child) => {
          child = setNewMenuPath(child);
          return {
            path: child.path,
            menu_path: child.path,
            menu_name: child.name,
            menu_icon: child.icon,
            display: child.display,
            layout_json: child.layout_json,
            url: child.url,
            params: child.params,
            type: child.type,
            custom_type: child.custom_type,
            custom_layout_usage: child.custom_layout_usage,
            object_code: child.object_code,
            open_type: child.open_type,
            id: child.id,
            package: child.package,
            children: (child.children || []).map((child1) => {
              child1 = setNewMenuPath(child1);
              return {
                path: child1.path,
                menu_path: child1.path,
                menu_name: child1.name,
                menu_icon: child1.icon,
                display: child1.display,
                layout_json: child1.layout_json,
                url: child1.url,
                params: child1.params,
                type: child1.type,
                custom_type: child1.custom_type,
                custom_layout_usage: child1.custom_layout_usage,
                object_code: child1.object_code,
                open_type: child1.open_type,
                id: child1.id,
                package: child1.package,
              };
            }),
          };
        }),
        id: item.id,
        package: item.package,
      };
    });
  }
  return [];
}

function getBreadcrumbFromMenu(menuList) {
  let breadcrumb = [];
  const fullPath = location.pathname + location.search; // 也可用完整的路径来匹配
  if (menuList.length) {
    // 认为有三级页面权限，一定有前几级页面权限
    const routerName = main.$route.name;
    const path =
      main.$route.query?.path || main.$route.query?.custom || main.$route.query?.iframe_src;
    let father = '';
    let child = '';
    Object.keys(MENU_ROUTE).forEach((item) => {
      if (item === routerName) {
        father = item;
      }
      if (MENU_ROUTE[item] && MENU_ROUTE[item].includes(routerName)) {
        father = item;
        child = routerName;
      }
    });
    if (father) {
      const li = [];
      findRouter(menuList, father, path, undefined, li);
      breadcrumb = li.reverse();
    }
    if (child) {
      const li = [];
      findRouter(menuList, child, path, undefined, li);
      const childcrumb = li.reverse();
      const breadcrumbPath = breadcrumb.map((item) => item.menu_path);
      breadcrumb = breadcrumb.concat(
        childcrumb.filter((child) => !breadcrumbPath.includes(child.menu_path)),
      );
    }
    if (!breadcrumb.length) {
      const li = [];
      findRouter(menuList, routerName, path, fullPath, li);
      breadcrumb = li.reverse();
    }
    // 删除无路径的一级菜单
    if (breadcrumb.length > 1 && !breadcrumb[0].menu_path) {
      breadcrumb = breadcrumb.slice(1);
    }
  }
  return breadcrumb;
}

/*修改面包屑名称*/
function setBreadcrumbName(breadcrumbList = [], routerNameObj = {}) {
  const allPaths = Object.keys(routerNameObj);
  if (!breadcrumbList.length || !allPaths.length) {
    return;
  }

  // 删除异常数据
  allPaths.forEach((path) => {
    const breadcrumbObj = routerNameObj[path];
    if (!breadcrumbObj.name || Math.abs(breadcrumbObj.ts - Date.now()) > 2000) {
      delete routerNameObj[item.menu_path];
    }
  });

  // 修改面包屑名称
  for (let index = breadcrumbList.length - 1; index >= 0; index--) {
    const item = breadcrumbList[index];
    const breadcrumbObj = routerNameObj[item.menu_path];
    if (!breadcrumbObj) {
      continue;
    }

    item.menu_name = breadcrumbObj.name;
    delete routerNameObj[item.menu_path];
  }
}

export default {
  namespaced: true,
  getters: {
    appList(state) {
      return state.managerLogin.app_list;
    },
    publishedAppList(state) {
      let { publishedAppList } = state;
      if (!publishedAppList?.length) {
        // 暂时不要使用(需求设计上权限有控制, 不同人员负责不同公众号)
        // 由于appList的字段经常空(比如 非员工账号 没有配置公众号小程序权限等等)
        // 自己会存下graphql查询出来的发布的公众号信息(和原始文章页面一致)
        // 最大可能获取全量数据
        publishedAppList = state.managerLogin.app_list;
      }
      return publishedAppList || [];
    },
    getEnabledWXAppList(state) {
      const appList = state.managerLogin?.app_list || [];
      if (appList.length) {
        return state.managerLogin.app_list.filter(
          // 1: 订阅号  2: 服务号 3: 小程序 (status 不一定返回了)
          (e) => (e.type === 1 || e.type === 2) && (!e.status || e.status === '1'),
        );
      }
      return appList;
    },
    getName(state) {
      return state.managerLogin.name;
    },
    getEnterpriseId(state) {
      return state.managerLogin.enterprise_id;
    },
    getTenantId(state) {
      return state.managerLogin.tenant_id;
    },
    getUserUin(state) {
      return state.managerLogin.user_uin;
    },
    getMenuList(state) {
      return state.menuList
        .filter((menu) => menu.display === 1)
        .map((menu) => ({
          ...menu,
          children: (menu.children || []).filter((menuChild) => menuChild.display === 1),
        }));
    },
    getMenuActiveIndex(state) {
      return state.menuActiveIndex;
    },
    getUserElementAuth(state) {
      return (code) => state.elementAuthList[code];
    },
    getUserId(state) {
      return state.managerLogin.user_id;
    },
    // 角色是否是管理员
    getIsAdmin(state) {
      return state.managerLogin.type === 'admin';
    },
    // 角色是否是操作员
    getIsOperator(state) {
      return state.managerLogin.type === 'operator';
    },
    getPageBreadcrumb(state) {
      return state.pageBreadcrumb;
    },
    getResultBreadcrumb(state) {
      return state.resultBreadcrumb;
    },
    getMenuBreadcrumb(state) {
      return getBreadcrumbFromMenu(state.menuList);
    },
    getPublishList(state) {
      // type 1 2是公众号，3是小程序
      return state.managerLogin.app_list?.filter((item) => {
        return item.type === 1 || item.type === 2;
      });
    },
    getLayoutJson(state) {
      const routerName = main.$route.name;
      const path = main.$route.query?.path; // 若带了path，则以path获取json配置
      const data = getLayoutJson(state.menuList, path || routerName);
      helpGuideUtil.setConf(data?._own_prop);
      return data;
    },
    // 用于根据path查找相关布局
    getLayoutJsonWithPath(state) {
      return (path) => {
        if (path) {
          const data = getLayoutJson(state.menuList, path);
          helpGuideUtil.setConf(data?._own_prop);
          return data;
        }
      };
    },
    getMiniProgramList(state) {
      return state.managerLogin.app_list.filter((e) => e.type === 3);
    },
    getLastInterest(state) {
      return state.interest;
    },
    getMessageCount(state) {
      return state.messageCount;
    },
    getFeedbackCount(state) {
      return state.feedbackCount;
    },
    getTerritoryList(state) {
      return state.staffUserInfo.territory_list;
    },
    getCurrentTerritory(state) {
      return state.staffUserInfo.current_territory;
    },
    getCurrentTerritoryType(state) {
      return state.staffUserInfo.current_territory_type || '';
    },
    getUserInfo(state) {
      return state.managerLogin;
    },
    getHeadimg(state) {
      return state.staffUserInfo.headimg;
    },
  },
  state: {
    // 登录用户当前绑定的信息
    managerLogin: {
      id: null,
      enterprise_id: null,
      name: null,
      uin: null,
      role: null,
      app_list: [],
      agent_list: [],
      element_list: [],
      tenant_id: null,
    },
    hasRight: false, // 当前页面是否有权限
    pageBreadcrumb: [], // 页面传入的固定面包屑
    resultBreadcrumb: [], // 计算完成后的面包屑
    tempBreadcrumbObj: {}, //设置当前页面面包屑 临时数据(设置的时候 可能面包屑未加载完成)
    menuList: [],
    menuActiveIndex: '',
    permissions: [], // 白名单
    interest: '',
    elementAuthList: {}, // 重楼元素权限配置
    messageCount: 0, // 站内信数量
    feedbackCount: 0, // 用户反馈数量
    staffUserInfo: {}, // 员工相关数据
    publishedAppList: [], // 已经发布公众号和小程序列表
  },
  mutations: {
    setManagerLogin(state, managerLogin) {
      state.managerLogin = managerLogin;
    },
    setUserAppList(state, appList) {
      state.managerLogin.app_list = appList;
    },
    setPublishedAppList(state, publishedAppList) {
      state.publishedAppList = publishedAppList;
    },
    setHasRight(state, hasRight) {
      state.hasRight = hasRight;
    },
    // 修改当前页面面包屑名称
    setCurrentPageBreadcrumbName(state, breadcrumbName) {
      if (!breadcrumbName) {
        return;
      }
      state.tempBreadcrumbObj[main.$route.name] = { name: breadcrumbName, ts: Date.now() };
      setBreadcrumbName(state.resultBreadcrumb, state.tempBreadcrumbObj);
    },
    // 页面调用的面包屑更新接口 这个数据只会使用一次就丢弃掉
    setBreadcrumb(state, pageBreadcrumb) {
      state.pageBreadcrumb = pageBreadcrumb;
    },
    setMenuList(state, menuList) {
      state.menuList = menuList || [];
    },
    setMenuActiveIndex(state, name) {
      state.menuActiveIndex = name;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setLastInterest(state, interest) {
      state.interest = interest;
    },
    setElementAuthList(state, elementAuthList) {
      state.elementAuthList = elementAuthList;
    },
    setMessageCount(state, count) {
      state.messageCount = count;
    },
    setFeedbackCount(state, count) {
      state.feedbackCount = count;
    },
    setStaffUserInfo(state, data) {
      state.staffUserInfo = data;
    },
    // 保存的面包屑结果
    setResultBreadcrumb(state, resultBreadcrumb) {
      state.resultBreadcrumb = resultBreadcrumb;
      setBreadcrumbName(resultBreadcrumb, state.tempBreadcrumbObj);
    },
  },
  actions: {
    async getManagerLogin(context) {
      await GetManagerLogin()
        .then((managerLogin) => {
          context.commit('setManagerLogin', managerLogin);
        })
        .catch((error) => {
          main.$message(error.message);
        });
    },
    async getManagerAppList(context) {
      const { id } = main.$store.state.user.managerLogin;
      console.log(id, 'id');
      if (id) {
        await getManagerInfoApi({
          id,
        }).then((res) => {
          context.commit('setUserAppList', res.app_list);
        });
      }
    },
    async getPublishedAppList(context) {
      await getPublishedAppListApi({})
        .then((res) => {
          context.commit('setPublishedAppList', res.official_accounts);
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async GetUserMenuTree(context) {
      await GetUserMenuTree()
        .then((res) => {
          const menuList = transMenuList(res?.data);
          context.commit('setMenuList', menuList);
          router.onReady(() => {
            let errMsg = '';
            const routerName = main.$route.name;
            const { rightFilter } = config;
            if (rightFilter.includes(routerName)) {
              // 进行 web 定开权限判断
              if (routerName === 'custom') {
                const customRouterName = main.$route.query.custom;
                if (customRouterName) {
                  // 需要判断两次，重楼跳转直接带有参数需要判断全路径
                  // hanlin: 有需要通过页面固定参数进入页面就有逻辑区分
                  let { hasRight, rightRouterName } = hasRouterRight(
                    menuList || [],
                    customRouterName,
                  );
                  // 页面中 action 跳转需要再判断单独的自定义路径
                  // az: 配置唯一路径，但跳转时需要添加 id 参数
                  if (!hasRight && customRouterName.indexOf('?') > -1) {
                    const symbolRouterCheck = hasRouterRight(
                      menuList || [],
                      customRouterName.split('?')[0],
                    );
                    hasRight = symbolRouterCheck.hasRight;
                    rightRouterName = symbolRouterCheck.rightRouterName;
                  }

                  if (hasRight) {
                  } else if (rightRouterName) {
                    main.g_tourl(rightRouterName, {
                      name: true,
                      replace: true,
                      params: { toFirstRightRouter: true },
                    });
                  } else {
                    errMsg = '您没有访问该系统的权限';
                  }
                } else {
                  // fix: 手动输入 /custom.html 跳登录死循环。简单处理，跳到首页。
                  main.g_tourl('/', { replace: true });
                }
              }
            } else if (menuList && menuList.length) {
              let menuItem = null;
              let rightRouterName = '';
              menuList.forEach((menu) => {
                if (menu.menu_path === routerName && !menuItem) {
                  menuItem = menu;
                }
                if (menu.menu_path && !rightRouterName && !rightFilter.includes(menu.menu_path)) {
                  rightRouterName = menu.menu_path;
                }
                if (menu.children && menu.children.length) {
                  menu.children.forEach((menuChild) => {
                    if (menuChild.menu_path === routerName && !menuItem) {
                      menuItem = menuChild;
                    }
                    if (
                      menuChild.menu_path &&
                      !rightRouterName &&
                      !rightFilter.includes(menu.menu_path)
                    ) {
                      rightRouterName = menuChild.menu_path;
                    }
                  });
                }
              });
              if (menuItem) {
              } else if (rightRouterName) {
                // 默认去有权限的第一个页面
                const name = !rightRouterName.includes('.html');
                main.g_tourl(rightRouterName, {
                  name,
                  replace: true,
                  params: { toFirstRightRouter: true },
                });
              } else {
                // 虽然有菜单，但没有配置path
                errMsg = '您没有可访问的页面';
              }
            } else {
              // 所有权限都没有，提示之后，去到登录页
              errMsg = '您没有访问该系统的权限';
            }
            if (errMsg) {
              main.$message.error(errMsg);
              main.g_tourl('noPermission', { name: true, replace: true });
            } else {
              context.commit('setHasRight', true);
            }
          });
        })
        .catch((error) => {
          main.$message(error.message);
        });
    },
    getPermission(context) {
      Promise.all([GetTenantObjectsPermission()]).then((vals) => {
        const permissions = [];
        vals.forEach((list) => {
          permissions.push(...list);
        });
        context.commit('setPermissions', permissions);
      });
    },
    getElementAuthList(context) {
      GetUserElementList().then((res) => {
        const listObj = {};
        (res?.data || []).forEach((item) => {
          listObj[item.code] = true;
        });
        context.commit('setElementAuthList', listObj);
      });
    },
    requestMessageCount(context) {
      NeedReadMessage().then(({ message }) => {
        context.commit('setMessageCount', message._aggregate.count);
      });
    },
    getStaffUserInfo(context) {
      return GetStaffUserInfo().then((res) => {
        context.commit('setStaffUserInfo', res);
      });
    },
    requestFeedbackCount(context, type) {
      NeedReadFeedback(type).then(({ usage_feedback }) => {
        context.commit('setFeedbackCount', usage_feedback?.[0]?.count);
      });
    },
  },
};
