import { cloneDeep } from 'lodash';

class MsgCreator {
  #config;
  #messages;
  #questionEntry;

  constructor({ config, messages, pageFrom }) {
    this.#config = config || {};
    this.#messages = messages || [];
    const questionEntry = config.question_entry ? cloneDeep(config.question_entry) : {};
    // 过滤问题列表
    let questionList = questionEntry.groups || [];
    questionList = questionList?.filter(
      (i) => !i.hidden && (!i.pages || i.pages.includes(pageFrom)),
    );
    questionEntry.groups = questionList;
    this.#questionEntry = questionEntry;
  }
  setupMessages(messages) {
    this.#messages = messages;
  }

  createMsg(params) {
    // { from, text, params, request, response }
    const msg = {
      chat_id: Math.random(),
      from: 'bot', // user bot
      time: new Date().getTime(),
      request: {},
      response: {},
      ...params,
    };

    const isUserMsg = msg.from === 'user';
    const msgParams = msg.params || {};
    const { action, bot_config: botConfig = {}, user_config: userConfig = {} } = msgParams;

    if (isUserMsg) {
      msg.can_copy = userConfig?.can_copy ?? this.#config.can_copy; // 复制
    } else {
      msg.can_copy = botConfig.can_copy ?? this.#config.can_copy; // 复制
      msg.can_retry = botConfig.can_retry ?? this.#config.can_retry; // 重试
      msg.can_use_text = botConfig.can_use_text; // 使用文本
    }

    if (action !== undefined) {
      if (isUserMsg) {
        msg.request.question = userConfig.label || msgParams.label;
        msg.request.user_config = userConfig;
      } else {
        msg.request.bot_config = {
          bot_footer_text: this.#config?.bot_footer_text, // '内容由AI生成，仅供参考'
          ...(botConfig || {}),
        };
        msg.request.loading = true;
        msg.response.reply = null;
      }
    }

    this.#messages.push(msg);
    return msg;
  }

  createTextMsg(from, text) {
    // { from, text, params, request, response }
    const params = { from };
    if (from === 'user') {
      params.request = { question: text ?? '' };
    } else {
      params.response = { reply: [{ text }] };
    }
    return this.createMsg(params);
  }

  createWelcomeMsg() {
    const text = this.#config.welcome_message;
    if (text) {
      return this.createTextMsg('bot', text);
    }
  }

  // 获取问题入口
  getQuestionEntry() {
    return this.#questionEntry;
  }

  // 创建问题列表
  createQuestionListMsg() {
    const questionList = this.#questionEntry.groups || [];
    if (questionList.length) {
      return this.createMsg({
        from: 'bot',
        type: 'question-list',
        question: questionList,
      });
    }
  }
  // 快捷入口切换到下面
  removeQuestionListMsg() {
    const index = this.#messages.findIndex((i) => i.type === 'question-list');
    if (index !== -1) {
      this.#messages.splice(index, 1);
    }
  }
}

export default MsgCreator;
