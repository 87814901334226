/* eslint-disable */
import { Select } from 'element-ui';
export default {
  extends: Select,
  name: 'ElSelect',
  props: {
    // 当开启filterable时是否使用插入的input
    useAppendInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchPlaceholder: '请搜索',
      appendElInputQuery: '',
    }
  },
  mounted() {
    if (this.filterable && this.useAppendInput) {
      this.$nextTick(() => {
        this.appendInputToFropdown();
      });
    }
  },
  computed: {
    readonly() {
      if (this.filterable && this.useAppendInput && this.$refs?.referenceSearch) {
        return true;
      }
      return Select.computed.readonly.call(this);
    },
  },
  methods: {
    resetInputHeight() {
      if (this.collapseTags && !this.filterable) return;
      this.$nextTick(() => {
        if (!this.$refs.reference) return;
        let inputChildNodes = this.$refs.reference.$el.childNodes;
        let input = [].filter.call(inputChildNodes, item => item.tagName === 'INPUT')[0];
        const tags = this.$refs.tags;
        const tagsHeight = tags ? Math.round(tags.getBoundingClientRect().height) : 0;
        const sizeInMap = this.initialInputHeight || 32; // override: 默认用32px设置高度
        input.style.height = this.selected.length === 0
          ? sizeInMap + 'px'
          : Math.max(
          tags ? (tagsHeight + (tagsHeight > sizeInMap ? 6 : 0)) : 0,
          sizeInMap
        ) + 'px';
        if (this.visible && this.emptyText !== false) {
          this.broadcast('ElSelectDropdown', 'updatePopper');
        }
      });
    },
    appendInputToFropdown() { 
      const poper = this.$refs.popper;
      const newNode = this.renderInputs();
      const tempInstance = new Vue({
        render: newNode
      }).$mount();
      if (poper && poper.$el) {
        const inputTags = this.$refs?.input;
        if (this.multiple && inputTags) {
          inputTags.setAttribute('readonly', true);
          inputTags.style.setProperty('visibility', 'hidden', 'important'); // 隐藏掉原来的input否则输入框绑定的值会被显示在select中
        }
        poper.$el.insertBefore(tempInstance.$el, poper.$el.firstChild);
        Object.keys(tempInstance.$refs).forEach(key => {
          this.$refs[key] = tempInstance.$refs[key];
        });
      }
    },
    renderInputs() {
      return (h) => {
        const elInput = h('el-input', {
          ref: 'referenceSearch',
          props: {
            value: this.appendElInputQuery,
            size: this.selectSize,
            clearable: this.clearable,
            validateEvent: false,
          },
          attrs: {
            placeholder: this.searchPlaceholder,
          },
          on: {
            input: (value) => {
              this.appendElInputQuery = value;
              this.debouncedOnInputChange();
            },
          }
        }, [
          h('template', { slot: 'suffix' }, [
            h('i', {
              class: 'el-input__icon el-icon-search',
            })
          ])
        ]);

        return h('div', {
          class: 'el-select-input-search',
          style: {
            width: '100%',
            display: 'inline-block',
            padding: '6px',
            position: 'relative'
          }
        }, [
          elInput,
        ]);
      };
    },
    managePlaceholder() {
      if (this.filterable && this.useAppendInput && this.$refs?.referenceSearch) {
        this.searchPlaceholder = this.$refs?.referenceSearch?.value ? '' : this.placeholder;
      } else {
        return Select.methods.managePlaceholder.call(this);
      }
    },
    onInputChange() {
      if (this.filterable && this.useAppendInput && this.$refs?.referenceSearch && this.query !== this.appendElInputQuery) {
        this.query = this.appendElInputQuery;
        this.handleQueryChange(this.query);
      } else {
        return Select.methods.onInputChange.call(this);
      }
    }
  },
};
