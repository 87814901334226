import { cloneDeep } from 'lodash';
import { getPathJson } from '../common';
import { query, getCurrentPage } from '../util';
import { cmdPayload, helpGuideUtil } from './util';
import { DataService } from '../api';
import { execExpression, execExpressionDeep, expressionGlobalFuncKey } from './expression';
import { easyQueryGql, queryGql, transData, getNewLayoutJson } from './queryUtil';
import request from '../request';
import { PAGE_SIZE } from '../constant';



// 根据path参数、当前链接route，获取存储在后台的json
function getJsonPath() {
  const path = query('path');
  let curPath = `/${getCurrentPage()?.route}`;
  curPath = location.pathname;
  
  return path || curPath;
}

function initGlobalFunc(json) {
  try {
    const { global_func: globalFunc } = json;
    if (globalFunc) {
      Object.keys(globalFunc).forEach((key) => {
        global[expressionGlobalFuncKey][key] = execExpression(globalFunc[key]);
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function initLayoutCallback(promiseFunc, disabledToast) {
  return promiseFunc
    .then((json) => {
      if (json) {
        const { title } = json;
        
        if (title) {
          // TODO: WEB后面有需求的支持
          
        }
        return json;
      }
      return Promise.reject(new Error('布局未配置'));
    })
    .then((json) => {
      try {
        getGlobalConfigGlobalFunc();
        initGlobalFunc(json);
      } catch (e) {
        console.log(e);
      }
      const { custom_json: customJson } = json;
      let prom;
      if (customJson?.query) {
        prom = DataService({
          query: customJson.query,
        });
      } else {
        prom = Promise.resolve();
      }
      if (customJson?.json) {
        return prom.then((res) => {
          execExpression(customJson.json, { t: res, json });
          return json;
        });
      }
      return json;
    })
    .catch((e) => {
      if (!disabledToast) {
        // 并不是所有页面都需要这个提示的(默认值沿用 历史逻辑 默认提示)
        
        console.error(e);
        
      }
    });
}

function initLayout(path = '') {
  let tmpPath = '';
  if (path) tmpPath = path;
  else tmpPath = getJsonPath();
  return initLayoutCallback(getPathJson(tmpPath)).then((data) => {
    helpGuideUtil.setConf(data?._own_prop);
    return data;
  });
}

let flag = false;
function getGlobalConfigGlobalFunc() {
  return new Promise((res) => {
    if (!flag) {
      getPathJson('globalConfigMini')
        .then((json) => {
          initGlobalFunc(json);
          flag = true;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          res();
        });
    } else {
      res();
    }
  });
}

function initNewLayout(path, layoutKey) {
  let tmpPath = '';
  tmpPath = (path ? path : getJsonPath()) || '';
  // 使用path和object组合，优先使用path的数据, 其次使用object新布局方式
  return initLayoutCallback(
    Promise.all([getPathJson(tmpPath), getNewLayoutJson(layoutKey)]).then((data) => {
      const jsonConf = data[0] || data[1] || null;
      helpGuideUtil.setConf(jsonConf?._own_prop);
      return jsonConf;
    }),
    layoutKey?.disabledToast
  );
}

async function easyQuery(json, extraCondition, extraPayload = {}) {
  const { object, trigger_ext: triggerExt } = json;
  const gql = easyQueryGql(json, extraCondition);
  const res = await DataService({
    query: gql,
    trigger_ext: triggerExt,
    ...extraPayload,
  });
  const resData = res[object];
  // 增加审批流相关的逻辑，注入结果中。
  if (res?.__workflow_conf_sw !== undefined && resData) {
    resData.__workflow_conf_sw = res.__workflow_conf_sw;
  }
  return resData;
}

// 列表通过gql查询数据
function listGqlQuery({ page = 1, json, parentData, layoutParams, objects, filters }) {
  // 通过元数据来请求数据
  const pageSize = json.render?.page_size || PAGE_SIZE;
  const params = {
    offset: (page - 1) * pageSize,
    limit: pageSize,
  };
  // 对where，order等做表达式处理
  let execExp = (exp, data) => {
    return execExpressionDeep(cloneDeep(exp), {
      json,
      t: {
        p: parentData,
        $filters: filters,
        $search: '',
      },
      p: layoutParams,
      $data: data,
    });
  };
  const newJson = {
    ...json,
    where: execExp(json?.where),
    wheres: execExp(json?.wheres),
    orders: execExp(json?.orders),
    sub_query: execExp(json?.sub_query),
  };
  return DataService({
    query: queryGql({
      json: newJson,
      objects,
      params,
      filters: [],
      input: '',
    }),
  })
    .then((data) => {
      let newData = data;
      if (json.response) {
        newData = execExp(json.response, data);
      }
      const { list, total } = transData({
        json,
        objects,
        data: newData,
      });
      return { page: page + 1, total: total || 0, list: [...list] };
    })
    .catch(() => {
      return { page: 1, total: 0, list: [] };
    })
    .finally(() => {
      execExp = null;
    });
}

// 列表通过cmd查询数据
function listCmdService({ page = 1, json, parentData, layoutParams, filters }) {
  const { cmd, response } = json || {};
  const pageSize = json.render?.page_size || PAGE_SIZE;
  return request(
    cmd,
    cmdPayload({
      json,
      page,
      pageSize,
      input: '',
      filters,
      parentData,
    })
  )
    .then((data) => {
      const { list, total } = execExpression(response, { t: data, p: layoutParams });
      return { page: page + 1, total: total || 0, list: [...list] };
    })
    .catch(() => {
      return { page: 1, total: 0, list: [] };
    })
    .finally(() => {});
}

function getPreFetchFilter({ json, parentData, layoutParams }) {
  const { render } = json || {};
  let { filters } = render || {};
  filters = cloneDeep(filters) || [];
  filters =
    execExpressionDeep(cloneDeep(filters), {
      json,
      t: {
        p: {
          ...parentData,
        },
      },
      p: layoutParams,
    }) || [];
  return filters;
}

// 列表通过解析json查询数据
function listGetDataWithJson({ page = 1, json, parentData, layoutParams, objects, filters = [] }) {
  if (json.cmd) {
    return listCmdService({
      page,
      json,
      parentData,
      layoutParams,
      filters,
    });
  }
  return listGqlQuery({
    page,
    json,
    parentData,
    layoutParams,
    objects,
    filters,
  });
}

export * from './util';
export * from './queryUtil';
export {
  initLayout,
  getJsonPath,
  easyQuery,
  listGetDataWithJson,
  initNewLayout,
  getPreFetchFilter,
  getGlobalConfigGlobalFunc,
};
