var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view',{staticClass:"icons-wrap"},[_c('view',{staticClass:"icon-btn",on:{"click":function($event){return _vm.setStatus('positive')}}},[_c('view',{class:[
        'iconfont',
        'iconfont-like',
        _vm.tmpFeedback === 'positive' ? 'iconfont-like-primary' : '' ]})]),_c('view',{staticClass:"icon-btn",on:{"click":function($event){return _vm.setStatus('negative')}}},[_c('view',{class:[
        'iconfont',
        'iconfont-like',
        'dislike',
        _vm.tmpFeedback === 'negative' ? 'iconfont-like-primary' : '' ]})])])}
var staticRenderFns = []

export { render, staticRenderFns }