import { isObject } from 'lodash';

function clipboardAction({ action }) {
  return new Promise((resolve) => {
    try {
      const { action_params: actionParams = {} } = action;
      const { type } = actionParams;
      if (type === 'set') {
        let { data } = actionParams;
        data = isObject(data) ? JSON.stringify(data) : data.toString();
        uni.setClipboardData({
          data,
          success() {
            uni.hideToast();
          },
          complete() {
            resolve();
          },
        });
      } else if (type === 'get') {
        uni.getClipboardData({
          success(res) {
            uni.hideToast();
            try {
              resolve(JSON.parse(res.data)); // data
            } catch (err) {
              console.error(err);
              resolve(res.data); // data
            }
          },
          fail() {
            resolve();
          },
        });
      } else {
        resolve();
      }
    } catch (err) {
      console.error(err);
      resolve();
    }
  });
}

export { clipboardAction };
