import {
  CreateArticleList,
  UpdateArticleList,
  ArticleListDetail,
  ArticleListArticle,
  GetArticleList,
  kvConfigQuery,
  GetArticleListValidScope,
  UpdateArticleListValidScope,
  InsertArticleListValidScope,
  DeleteArticleListValidScope,
} from '@/graphql/gql/articleList';
import { calVariables } from '@/graphql/util';
import { DataService } from 'nges-common/src/web/utils/api';

export function queryGetArticleList(data) {
  return DataService({
    query: calVariables(GetArticleList, data),
  });
}

export function queryCreateArticleList(data) {
  return DataService({
    query: calVariables(CreateArticleList, data),
  });
}

export function queryUpdateArticleList(data) {
  return DataService({
    query: calVariables(UpdateArticleList, data),
  });
}

export function queryArticleListDetail(data) {
  return DataService({
    query: calVariables(ArticleListDetail, data),
  });
}

export function queryArticleListArticle(data) {
  return DataService({
    query: calVariables(ArticleListArticle, data),
  });
}

export function querykvConfigQuery(data) {
  return DataService({
    query: calVariables(kvConfigQuery, data),
  });
}

export function queryArticleListValidScope(data) {
  return DataService({
    query: calVariables(GetArticleListValidScope, data),
  });
}

export function updateArticleListValidScope(data) {
  return DataService({
    query: calVariables(UpdateArticleListValidScope, data),
  });
}
export function insertArticleListValidScope(data) {
  return DataService({
    query: calVariables(InsertArticleListValidScope, data),
  });
}
export function deleteArticleListValidScope(data) {
  return DataService({
    query: calVariables(DeleteArticleListValidScope, data),
  });
}
